import { BaseButton } from "../../../../../../../components/ui";

const renderValidateOrApplyButton = (
	isValidating: boolean,
	isValid: boolean,
	textareaValue: string,
	handleVerifySerialNumbers: () => void,
	handleApplySerialNumbers: () => void,
	frontEndValidationResults: any // Add the appropriate type here
) => {
	// if the text area is empty, and nothing has been validated
	// show the verify button in disabled state
	if (!isValidating && !isValid) {
		return (
			<BaseButton
				text="Verify"
				bgColor={!textareaValue ? "bg-navy-270" : "bg-primary"}
				fontColor={"text-white"}
				hoverBackground="hover:bg-primaryHover"
				borderColor={
					!textareaValue ? "border-navy-270" : "border-primary"
				}
				additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4 border-1"
				shape="rounded-full"
				as="button"
				fontFamily="font-omnes-medium"
				buttonSize="h-7 w-130px"
				iconClasses="pr-2 text-xs"
				onClick={handleVerifySerialNumbers}
				isDisabled={!textareaValue}
				disabledButtonClasses="bg-navy-270"
				fontSize="text-sm"
			/>
		);
	}

	// if the user has added serial numbers, and selected validate
	// show the apply serial #'s button in disabled state
	if (isValidating) {
		return (
			<BaseButton
				text="Apply Serial #s"
				fontColor="text-white"
				additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4"
				shape="rounded-full"
				as="button"
				fontFamily="font-omnes-medium"
				buttonSize="h-7 w-130px"
				isDisabled={true}
				disabledButtonClasses="bg-navy-270"
				fontSize="text-sm"
			/>
		);
	}

	// if the user has added serial numbers, and selected validate, and all serial numbers are valid
	// show the apply serial #'s button in disabled state
	if (
		(!isValidating &&
			textareaValue &&
			frontEndValidationResults &&
			frontEndValidationResults.checkSerialNumbers &&
			frontEndValidationResults.checkSerialNumbers.length > 0) ||
		(!isValidating && textareaValue)
	) {
		return (
			<>
				<BaseButton
					text="Verify"
					bgColor={!textareaValue ? "bg-navy-270" : ""}
					fontColor={!textareaValue ? "text-white" : "text-primary"}
					hoverBackground="hover:bg-primaryHoverLight"
					borderColor={
						!textareaValue ? "border-navy-270" : "border-primary"
					}
					additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4 border-1"
					shape="rounded-full"
					as="button"
					fontFamily="font-omnes-medium"
					buttonSize="h-7 w-130px"
					iconClasses="pr-2 text-xs"
					onClick={handleVerifySerialNumbers}
					isDisabled={!textareaValue}
					fontSize="text-sm"
				/>
				<BaseButton
					text="Apply Serial #s"
					bgColor="bg-primary"
					fontColor="text-white"
					hoverBackground="hover:bg-primaryHover"
					additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4"
					shape="rounded-full"
					as="button"
					fontFamily="font-omnes-medium"
					buttonSize="h-7 w-130px"
					iconClasses="pr-2 text-xs"
					onClick={handleApplySerialNumbers}
					isDisabled={false}
					fontSize="text-sm"
				/>
			</>
		);
	}
	return null;
};

export default renderValidateOrApplyButton;
