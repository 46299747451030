const getDynamicDefaultValue = (
	serialNumbers: { [key: string]: string[] },
	selectedPartNumber: string,
	index: number,
	applySerialNumbers: boolean,
	getValues: any
) => {
	return serialNumbers[selectedPartNumber]?.length > 0
		? serialNumbers[selectedPartNumber][index] || ""
		: "";
};

export default getDynamicDefaultValue;
