import React, { useState } from "react";
import Section from "../../../components/layout/Section";
import PrimaryTable from "../../../components/ui/Tables/PrimaryTable/PrimaryTable";
import { ItemsContextProvider } from "../../../hooks/dataTableHooks/useItemsContext";
import { DataTableProvider } from "../../../hooks/dataTableHooks/useDataTableContext";
import BaseText from "../../../components/ui/BaseText";
import BaseButton from "../../../components/ui/BaseButton";
import usePageListDetails from "../../PageHooks/usePageListDetails";
import { getFontAwesomeIcon } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { CreateVendorItemModal } from "../view/modals";
const VendorItemsPage: React.FC = () => {
	const [pageTitle, setPageTitle] = useState<string>("Vendor Items");
	const [hasModalTable, setHasModalTable] = useState<boolean>(true);
	const [data, setData] = useState<any[]>([]);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { pageMeta } = usePageListDetails("vendor-items-listing");
	const [isListingModalOpen, setIsListingModalOpen] =
		useState<boolean>(false);

	// Building Additional Data object
	const handleCreateVendorItemModalClick = () => {
		setIsOpen((prevState) => !prevState);
	};

	const modal = (
		<CreateVendorItemModal
			isOpen={isOpen}
			handleCreateVendorItemModalClick={handleCreateVendorItemModalClick}
		/>
	);
	//Building Additional Data object
	const additionalData = {
		slug: "Items.catalogId",
		uuid: "1",
	};

	const baseButtonClassNames =
		"text-sm bg-primary text-white px-4 flex items-center gap-2 hover:bg-primaryDefaultHover border-none font-omnes-medium";
	const containerClassNames =
		"pt-10 px-[60px] min-h-full max-h-full font-omnes-regular";
	const sectionClassNames = "flex justify-between pb-3";

	return (
		<div className={containerClassNames}>
			<Section additionalClasses={sectionClassNames}>
				<div className="flex items-center">
					{getFontAwesomeIcon("store", "solid")}
					<BaseText
						text={pageTitle}
						additionalClasses="pl-2"
						size="text-22px"
						color="text-navy-500"
						tag="h1"
						fontFamily="font-omnes-medium"
					/>
				</div>
				<BaseButton
					icon={getFontAwesomeIcon("plus", "solid")}
					iconClasses="text-sm"
					text="Add Vendor Item"
					type="button"
					onClick={handleCreateVendorItemModalClick}
					additionalClasses={baseButtonClassNames}
					shape="rounded-full"
					buttonSize="h-26px"
				/>
			</Section>
			<DataTableProvider pageMeta={pageMeta} pageTitle={pageTitle}>
				<ItemsContextProvider hasModalTable={false} data={data}>
					<PrimaryTable
						pageTitle={pageTitle}
						hasModalTable={hasModalTable}
						setPageTitle={setPageTitle}
						setHasModalTable={setHasModalTable}
						slug={"vendor-items"}
						setData={setData}
						additionalData={additionalData}
					/>
				</ItemsContextProvider>
			</DataTableProvider>
			{modal}
		</div>
	);
};

export default VendorItemsPage;
