import { FormValidationLoadingMessage } from "../../../../../../../components/misc";
import { getFontAwesomeIcon } from "../../../../../../../utils";
import { ValidationResult, SerialNumbers } from "../../../../types";

const renderTableValidationMessage = (
	validationResult: ValidationResult,
	serialNumbers: SerialNumbers,
	uuid: string
) => {
 	const validIconClasses = `flex items-center text-mint-600 text-xl pr-1 font-omnes-medium`;
	const notValidIconClasses =
		"flex items-center text-crimson-700 text-xl pr-1";
	if (validationResult && serialNumbers[uuid]?.length > 0) {
		const validationResultDisplay =
			validationResult.isFrontEndValid &&
			validationResult.netSuiteValidationResult;

		const iconClasses = validationResultDisplay
			? validIconClasses
			: notValidIconClasses;

		return (
			<FormValidationLoadingMessage
				isValidating={false}
				isValid={validationResultDisplay ?? false}
				loadingMessage=""
				loadingMessageClasses=""
				loadingIconClasses="size-7"
				loadingContainerClasses="absolute top-[-10px]"
				loadingMotionContainerClasses="relative"
				validMessage=""
				notValidMessage=""
				notValidMessageClasses="text-crimson-700 font-omnes-medium"
				validMessageClasses="text-mint-800"
				validIconClasses={iconClasses}
				notValidIconClasses={iconClasses}
				validIcon={getFontAwesomeIcon("circleCheck", "solid")}
				notValidIcon={getFontAwesomeIcon("circleXmark", "solid")}
				placeholderMessage=""
				placeholderMessageClasses="px-1"
			/>
		);
	}
	return null;
};
export default renderTableValidationMessage;
