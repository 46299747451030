import React from "react";
import { getFontAwesomeIcon } from "../../utils";
import InitialsAvatar from "./InitialsAvatar";
interface BaseContactInfoTypes {
	children?: React.ReactNode;
	columnContainerClasses?: string;
	columnShape?: "rounded-full" | "semi-rounded" | "none";
	borderColor?: string;
	backgroundColor?: string;
	additionalClasses?: string;
 	contactData?: {
		firstName: string;
		lastName: string;
		primaryContactEmailAddress: {
			emailAddress: string;
		};
		primaryContactPhoneNumber: {
			phoneNumber: string;
		};
	};
}

const BaseContactInfo: React.FC<BaseContactInfoTypes> = ({
	children,
	contactData,
	additionalClasses,
}) => {
	const contactIconClasses =
		"flex items-center justify-center size-[60px] rounded-full bg-navy-200 max-2xl:size-10";
	const nameClasses = "text-left text-sm font-omnes-medium text-navy-500";
	const phoneEmailClasses =
		"font-omnes-regular text-sm text-left text-navy-400";

	if (contactData === null || contactData === undefined) {
		return (
			<div className={`flex items-center ${additionalClasses}`}>
				<div className={contactIconClasses}>
					<p>{getFontAwesomeIcon("user", "solid")}</p>
				</div>
				<div className="flex items-start pl-2 ">
					<p className={nameClasses}>No Contact Data Provided</p>
				</div>
			</div>
		);
	} else {

		return (
			<div className={`flex ${additionalClasses}`}>
				<InitialsAvatar
					firstName={contactData.firstName}
					lastName={contactData.lastName}
					containerClasses={contactIconClasses}
					textClasses="font-omnes-medium text-navy-500"
				/>
				<div className="flex flex-col items-start pl-2">
					<p className={nameClasses}>
						{contactData.firstName} {contactData.lastName}
					</p>
					<p className={phoneEmailClasses}>
						{contactData.primaryContactEmailAddress.emailAddress}
					</p>
					<p className={phoneEmailClasses}>
						{contactData.primaryContactPhoneNumber?.phoneNumber}
					</p>
				</div>
			</div>
		);
	}
};

export default BaseContactInfo;
