import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import BaseButton from "../ui/BaseButton";
import SupplyPortal from "./SupplyPortal";
import { fadeInOutVariants } from "../../motionVariants";

type SupplyFormGuardrailProps = {
	messages: {
		messageOne: string;
		messageTwo: string;
		isOpen: boolean;
	};
	buttonText?: {
		leftButtonText?: string;
		centerButtonText?: string;
		rightButtonText?: string;
	};
	classNames?: string;
	onDoNotSave?: () => void;
	onSave?: () => void;
	onCancel?: () => void;
	buttonContainerClasses?: string;
	rightButtonClasses?: string;
	centerButtonClasses?: string;
	leftButtonClasses?: string;
	rightSideButtonContainerClasses?: string;
};

const SupplyFormGuardrail: React.FC<SupplyFormGuardrailProps> = ({
	messages,
	buttonText = {
		leftButtonText: "Don't Save",
		centerButtonText: "Cancel",
		rightButtonText: "Save",
	},
	classNames = "bg-navy-300 border-1 border-navy-600 text-navy-800 rounded-lg",
	onDoNotSave,
	onSave,
	onCancel,
	buttonContainerClasses = "flex justify-between",
	rightButtonClasses = "bg-primary text-white hover:bg-primaryHover rounded-full h-26px py-1.5 px-6 mx-2 max-sm:mx-1 font-omnes-medium text-sm",
	centerButtonClasses = "bg-white text-primary hover:bg-primaryHoverLight border-primary border-1 rounded-full h-26px py-1.5 px-6 mx-2 max-sm:mx-1 font-omnes-medium text-sm",
	leftButtonClasses = "bg-white text-primary hover:bg-primaryHoverLight border-primary border-1 rounded-full h-26px py-1.5 px-3 max-sm:mx-1 font-omnes-medium text-sm",
	rightSideButtonContainerClasses = "flex",
}) => {
	const [isVisible, setIsVisible] = useState(messages.isOpen);

	useEffect(() => {
		setIsVisible(messages.isOpen);
	}, [messages.isOpen]);

	const handelClose = () => {
		messages.isOpen = false;
		setIsVisible(false);
		if (onCancel) {
			onCancel();
		}
	};

	const handleDoNotSave = () => {
		if (onDoNotSave) {
			onDoNotSave();
		}
		setIsVisible(false);
	};

	const handleSave = () => {
		if (onSave) {
			onSave();
		}
		messages.isOpen = false;
		setIsVisible(false);
	};

	const supplyFormGuardrailClasses = `fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[9921] w-[600px] h-auto p-5 bg-transparent bg-white flex flex-col justify-between shadow-md ${classNames} `;

	return (
		<SupplyPortal containerId="portal-root">
			<AnimatePresence>
				{isVisible && (
					<motion.div
						{...fadeInOutVariants}
						className={supplyFormGuardrailClasses}
					>
						<div className="flex flex-col items-start pb-5">
							<p className="pb-3 font-omnes-medium text-base">
								{messages.messageOne}
							</p>
							<p className="font-omnes-regular text-base">
								{messages.messageTwo}
							</p>
						</div>
						<div className={buttonContainerClasses}>
							{buttonText.leftButtonText && (
								<BaseButton
									text={buttonText.leftButtonText}
									additionalClasses={` ${leftButtonClasses}`}
									shape="rounded"
									as="button"
									onClick={() => handleDoNotSave()}
								/>
							)}
							<div className={rightSideButtonContainerClasses}>
								<BaseButton
									text={buttonText.centerButtonText}
									additionalClasses={` ${centerButtonClasses}`}
									as="button"
									onClick={() => handelClose()}
								/>
								<BaseButton
									type="submit"
									text={buttonText.rightButtonText}
									additionalClasses={`${rightButtonClasses}`}
									as="button"
									onClick={() => handleSave()}
								/>
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</SupplyPortal>
	);
};

export default SupplyFormGuardrail;
