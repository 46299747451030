// GENERAL
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

// COMPONENTS
import Section from "../../../components/layout/Section";
import BaseButton from "../../../components/ui/BaseButton";
import BaseText from "../../../components/ui/BaseText";
import UpdateShipmentForm from "./components/forms/UpdateShipmentForm";
import { LoadingMessage } from "../../../components/misc";

// TYPES, FIELDS, UTILS, HOOKS
import getFontAwesomeIcon from "../../../utils/getFontAwesomeIcon";
import {
	ShippingCarrier,
	State,
	Country,
	UpdatedState,
	ModifiedStatesOptions,
} from "./types";
import {
	getAddress,
	getCountries,
	getShipmentDetails,
	getShippingCarriers,
	getStates,
	getItemFulfillmentCount,
} from "../api/UpdateShipmentApi";

const UpdateShipmentPage: React.FC = () => {
	const [pageTitle, setPageTitle] = useState<string>("Update Info");
	const [data, setData] = useState<any[]>([]);
	const [address, setAddress] = useState<UpdatedState[]>([]);
	const [shippingCarriers, setShippingCarriers] = useState<ShippingCarrier[]>(
		[]
	);
	const [states, setStates] = useState<ModifiedStatesOptions | null>();
	const [countries, setCountries] = useState<Country[]>([]);
	const query = new URLSearchParams(useLocation().search);
	const internalId = query.get("internalId");
	const shipmentItemsUrl = `/shipment-items?internalId=${internalId}`;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [shipmentNumber, setShipmentNumber] = useState<string>("");
	const navigate = useNavigate();

	// Helper function to fetch address, reformat data to flatten, and ensure no null values returned
	const fetchAndTransformAddress = async (internalId: string) => {
		const address = await getAddress(internalId || "");
		if (
			address.salesOrders &&
			address.salesOrders[0] &&
			address.salesOrders[0].shipToAddress
		) {
			const shipToAddress = address.salesOrders[0].shipToAddress;
			const transformedAddress = {
				...shipToAddress,
				state: {
					uuid: shipToAddress.state?.uuid || "",
					name: shipToAddress.state?.name || "",
				},
				street: shipToAddress.street || "",
				city: shipToAddress.city || "",
				zip: shipToAddress.zip || "",
				uuid: shipToAddress.uuid || "",
			};

			for (const key in transformedAddress) {
				if (
					transformedAddress[key] === null ||
					transformedAddress[key] === undefined
				) {
					transformedAddress[key] = "";
				}
			}

			setAddress(transformedAddress);
		}
	};

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const data = await getShipmentDetails(internalId || "");
			setData(data);
			const shippingCarriers = await getShippingCarriers();
			let carrierOptions;
			if (shippingCarriers.shippingCarriers) {
				carrierOptions = {
					shippingCarriers: shippingCarriers.shippingCarriers.slice(
						0,
						1
					),
				};
			}
			setShippingCarriers(carrierOptions as any);

			const states = await getStates();
			const updatedStates = {
				states: states.states.map((state: State) => ({
					uuid: state.uuid,
					name: state.name,
				})),
			};
			setStates(updatedStates);

			const country = await getCountries();
			setCountries(country);

			await fetchAndTransformAddress("" + internalId);

			const itemFulfillmentCount = await getItemFulfillmentCount(
				internalId || ""
			);
			setShipmentNumber(itemFulfillmentCount);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [internalId, navigate]);

	//Building Additional Data object
	const additionalData = {
		slug: "catalogId",
		uuid: "1",
	};

	// TODO: ask Rohan about changes to the shippingCarriers array, now coming in with duplicates
	const optionsArray = [shippingCarriers, states, countries];

	// helper function to ensure no null values are returned in options array
	const checkOptionsArrays = (optionsArray: any) => {
		if (optionsArray) {
			for (const option of optionsArray) {
				if (option === null) {
					return "No options provided";
				}
			}
			return optionsArray;
		}
		return false;
	};

	////////////////////////////////////////////// STYLES ////////////////////////////////////////////
	const contentSectionClasses = `flex flex-col items-start bg-navy-100 border-1 border-navy-200 rounded-xl w-[1820px] max-[189px]:w-[1600px] overflow-scroll mb-8`;
	const pageClasses =
		"flex justify-center pt-24 px-60px min-h-full max-h-full font-omnes-regular mb-10 overflow-x-scroll";
	const buttonSectionClasses = "flex flex-col items-start pb-5 pt-0";
	const formSectionClasses =
		"flex flex-col items-start w-full max-[1730px]:w-[1520px]";
	//////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		optionsArray && (
			<AnimatePresence>
				<div className={pageClasses}>
					<Section
						additionalClasses={contentSectionClasses}
						padding="p-10"
					>
						<Section additionalClasses={buttonSectionClasses}>
							<BaseButton
								text="Select Items"
								fontColor="supply-blue-500"
								additionalClasses="text-supply-blue-500 hover:text-supply-blue-300"
 								icon={getFontAwesomeIcon(
									"chevronLeft",
									"regular"
								)}
								iconClasses="text-xl pr-2"
								fontFamily="font-omnes-regular"
								as="link"
								to={shipmentItemsUrl}
							/>
							<BaseText
								text={pageTitle}
								additionalClasses="py-4"
								size="text-32px"
								color="text-navy-500"
								tag="h1"
								fontFamily="font-omnes-medium"
							/>
							<BaseText
								text="Add serial numbers and update shipment info."
								additionalClasses=""
								size="text-base"
								color="text-navy-500"
								tag="h1"
								fontFamily="font-omnes-regular"
							/>
						</Section>

						<Section
							additionalClasses={formSectionClasses}
							padding="mb-5"
						>
							{isLoading ? (
								<motion.div
									initial={{ opacity: 1 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{
										duration: 0.4,
										ease: "easeInOut",
									}}
									className="flex items-center justify-center w-full "
								>
									<div className="h-full">
										<LoadingMessage message={""} />
									</div>
								</motion.div>
							) : (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{
										ease: "easeOut",
										duration: 0.4,
									}}
									className="w-full"
								>
									<UpdateShipmentForm
										formClasses="flex flex-col w-full"
										optionsArray={checkOptionsArrays(
											optionsArray
										)}
										shipmentAddress={address}
										shipmentNumber={shipmentNumber}
									/>
								</motion.div>
							)}
						</Section>
					</Section>
				</div>
			</AnimatePresence>
		)
	);
};

export default UpdateShipmentPage;
