import React from "react";
import BaseText from "../../../../../components/ui/BaseText";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import BaseToolTip from "../../../../../components/ui/BaseToolTip";
import { motion } from "framer-motion";
import formatCurrency from "../../../../../utils/formatCurrency";
import { ApprovalContentSectionProps } from "./types";
import { getFontAwesomeIcon } from "../../../../../utils";

// FIGMA DOCUMENTATION (lease pricing fields + displaying bundles+bundle items): https://www.figma.com/design/wtVOcbRCEL5r3Kj0UgrB4C/Supply?node-id=122-12

const ApprovalContentSection: React.FC<ApprovalContentSectionProps> = ({
	orderFor,
	assignedManager,
	shipToAddress,
	reasonForRequest,
	costCenter,
	orderSummary,
	notificationEmail,
	shippingMethod,
	shippingPriority,
	shippingInstructions,
	orderTotalLease,
}) => {
	const { tax, total, shipping, subTotal } = orderSummary;

	const priorityFlagColor =
		shippingPriority === "High" ? "text-crimson-700" : "text-indigo-400";

	const formattedTax =
		formatCurrency(parseFloat((tax || "0").replace(/[$,]/g, ""))) || "—";
	const formattedTotal =
		formatCurrency(parseFloat((total || "0").replace(/[$,]/g, ""))) || "—";
	const formattedShipping =
		formatCurrency(parseFloat((shipping || "0").replace(/[$,]/g, ""))) ||
		"—";
	const formattedSubTotal =
		formatCurrency(parseFloat((subTotal || "0").replace(/[$,]/g, ""))) ||
		"—";
	let formattedComputerSupport =
		formatCurrency(Number(orderTotalLease)) || "0.00";

	const orderDetailsGridClasses = "grid grid-cols-3 mb-5 max-sm:grid-cols-2";

	return (
		<BaseContainer
			columnContainerClasses="md:flex w-full justify-between bg-white rounded-xl border border-gray-200 mb-2 max-sm:p-2 "
			columnShape="semi-rounded"
			borderColor="none"
			backgroundColor="white"
			isDisabled={false}
		>
			<motion.div
				className=" w-2/3 text-left"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.2, ease: "easeOut" }}
			>
				<div className={orderDetailsGridClasses}>
					<div className="col-span-1">
						<BaseText
							text="Order for"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						<BaseText
							text={orderFor || "—"}
							size="text-sm"
							color="text-gray-900"
							fontFamily="font-inter-regular"
							tag="p"
							additionalClasses="mt-2"
						/>
					</div>
					<div className="col-span-1">
						<BaseText
							text="Assigned Manager"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						<BaseText
							text={assignedManager || "—"}
							size="text-sm"
							color="text-gray-900"
							fontFamily="font-inter-regular"
							tag="p"
							additionalClasses="mt-2"
						/>
					</div>
					<div className="col-span-1 max-sm:mt-5">
						<BaseText
							text="Priority"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium "
							tag="h3"
						/>
						<div className="flex items-center mt-2">
							{shippingPriority && (
								<div
									className={`pr-2 text-base ${priorityFlagColor}`}
								>
									{getFontAwesomeIcon("flag", "solid")}
								</div>
							)}
							<BaseText
								text={shippingPriority || "—"}
								size="text-sm"
								color="text-gray-900"
								fontFamily="font-inter-regular"
								tag="p"
								additionalClasses=""
							/>
						</div>
					</div>
				</div>

				<div className={orderDetailsGridClasses}>
					<div className="col-span-1">
						<BaseText
							text="Shipping Address"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						<div className="flex flex-col mt-2">
							<BaseText
								text={shipToAddress?.addressee}
								size="text-sm"
								color="text-gray-900"
								fontFamily="font-inter-regular"
								tag="p"
							/>
							{shipToAddress?.attention && (
								<BaseText
									text={`Attn: ${shipToAddress.attention}`}
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							)}
							<BaseText
								text={shipToAddress?.line1}
								size="text-sm"
								color="text-gray-900"
								fontFamily="font-inter-regular"
								tag="p"
							/>
							{shipToAddress?.line2 && (
								<BaseText
									text={shipToAddress?.line2}
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							)}
							<div className="flex flex-row">
								<BaseText
									text={shipToAddress?.city}
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
									additionalClasses="mr-2"
								/>
								<BaseText
									text={shipToAddress?.state?.code}
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							</div>
							<BaseText
								text={shipToAddress?.zip}
								size="text-sm"
								color="text-gray-900"
								fontFamily="font-inter-regular"
								tag="p"
							/>
							{shipToAddress?.phoneNumber && (
								<BaseText
									text={shipToAddress?.phoneNumber}
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							)}
						</div>
					</div>
					<div className="col-span-2">
						<BaseText
							text="Reason for Request"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						{reasonForRequest.length > 0 ? (
							reasonForRequest.map((note, index) => (
								<BaseText
									key={index}
									text={note || "—"}
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							))
						) : (
							<BaseText
								text="—"
								size="text-sm"
								color="text-gray-900"
								fontFamily="font-inter-regular"
								tag="p"
							/>
						)}
					</div>
				</div>
				<div className={orderDetailsGridClasses}>
					<div className="col-span-1">
						<BaseText
							text="Shipping Method"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						<div className="mt-2">
							<BaseText
								text={shippingMethod || "—"}
								size="text-sm"
								color="text-gray-900"
								fontFamily="font-inter-regular"
								tag="p"
							/>
						</div>
					</div>
					<div className="col-span-2">
						<BaseText
							text="Special Instructions"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						{shippingInstructions.length > 0 ? (
							shippingInstructions.map((note, index) => (
								<BaseText
									key={index}
									text={note || "—"}
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							))
						) : (
							<BaseText
								text="—"
								size="text-sm"
								color="text-gray-900"
								fontFamily="font-inter-regular"
								tag="p"
							/>
						)}
					</div>
				</div>
				<div className={orderDetailsGridClasses}>
					<div className="col-span-1">
						<BaseText
							text="Notifications"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						<div className="mt-2">
							{notificationEmail.length > 0 ? (
								notificationEmail.map((email, index) => (
									<BaseText
										key={index}
										text={email || "—"}
										size="text-sm"
										color="text-gray-900"
										fontFamily="font-inter-regular"
										tag="p"
									/>
								))
							) : (
								<BaseText
									text="—"
									size="text-sm"
									color="text-gray-900"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							)}
						</div>
					</div>
					<div className="col-span-1">
						<BaseText
							text="Cost Center"
							size="text-sm font-semibold"
							color="text-navy-500"
							fontFamily="font-inter-medium"
							tag="h3"
						/>
						<BaseText
							text={costCenter || "—"}
							size="text-sm"
							color="text-gray-900"
							fontFamily="font-inter-regular"
							tag="p"
							additionalClasses="mt-2"
						/>
					</div>
				</div>
			</motion.div>

			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.2, ease: "easeOut" }}
				className="w-1/5 text-left max-md:w-1/2 max-md:mt-5 max-sm:w-full"
			>
				<BaseText
					color=""
					text="Order Summary"
					size="text-sm font-semibold"
					fontFamily="font-inter-medium"
					tag="h3"
					additionalClasses="mb-2"
				/>
				<div className="space-y-2">
					<div className="flex justify-between font-inter-regular items-center">
						<BaseText
							color="text-navy-500"
							text={`Subtotal`}
							size="text-sm"
							fontFamily="font-inter-medium"
							tag="p"
						/>
						<BaseText
							color="text-navy-500"
							text={`$${formattedSubTotal}`}
							size="text-sm"
							fontFamily="font-inter-medium"
							tag="p"
						/>
					</div>
					<div className="flex justify-between font-inter-regular items-center">
						<div className="flex items-center gap-2">
							<BaseText
								color="text-navy-500"
								text={`Est. Tax`}
								size="text-sm"
								fontFamily="font-inter-medium"
								tag="p"
							/>
							<BaseToolTip
								message="A projected cost based on current data."
								icon="infoCircle"
								iconStyle="solid"
							/>
						</div>
						<BaseText
							color="text-navy-500"
							text={`+ $${formattedTax}`}
							size="text-sm"
							fontFamily="font-inter-medium"
							tag="p"
						/>
					</div>
					<div className="flex justify-between font-inter-regular items-center">
						<div className="flex items-center gap-2">
							<BaseText
								color="text-navy-500"
								text={`Shipping`}
								size="text-sm"
								fontFamily="font-inter-medium"
								tag="h3"
							/>
							<BaseToolTip
								message="A projected cost based on current data."
								icon="infoCircle"
								iconStyle="solid"
							/>
						</div>
						<BaseText
							color="text-navy-500"
							text={`+ $${formattedShipping}`}
							size="text-sm"
							fontFamily="font-inter-medium"
							tag="p"
						/>
					</div>
					<div className="flex justify-between border-b border-gray-200"></div>
					<div className="flex justify-between font-inter-medium items-center">
						<BaseText
							color="text-navy-500"
							text="Total"
							size="text-sm font-semibold"
							fontFamily="font-inter-medium"
							tag="p"
						/>
						<BaseText
							color="text-navy-500"
							text={`$${formattedTotal}`}
							size="text-sm"
							fontFamily="font-inter-medium font-semibold"
							tag="p"
						/>
					</div>
				</div>
				{formattedComputerSupport !== "0.00" && (
					<div className="mt-10">
						<BaseText
							color=""
							text="Recurring Charges"
							size="text-sm font-semibold"
							fontFamily="font-inter-medium"
							tag="h3"
							additionalClasses="mb-2"
						/>
						<div className="space-y-2">
							<div className="flex justify-between items-center">
								<BaseText
									color="text-navy-500"
									text="Computer Support"
									size="text-sm"
									fontFamily="font-inter-regular"
									tag="p"
								/>
								<BaseText
									color="text-navy-500"
									text={`$${formattedComputerSupport}/mo`}
									size="text-sm"
									fontFamily="font-inter-regular"
									tag="p"
								/>
							</div>

							<div className="flex justify-between border-b border-gray-200"></div>
							<div className="flex justify-between items-center">
								<BaseText
									color="text-navy-500"
									text="Total"
									size="text-sm font-semibold"
									fontFamily="font-inter-medium"
									tag="p"
								/>
								<BaseText
									color="text-navy-500"
									text={`$${formattedComputerSupport}/mo`}
									size="text-sm"
									fontFamily="font-inter-medium  font-semibold"
									tag="p"
								/>
							</div>
						</div>
					</div>
				)}
			</motion.div>
		</BaseContainer>
	);
};

export default ApprovalContentSection;
