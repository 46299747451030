import React, { useState, useEffect } from "react";
import Section from "../../../components/layout/Section";
import useExportableData from "../../../hooks/dataTableHooks/useExportableData";
import { DataTableProvider } from "../../../hooks/dataTableHooks/useDataTableContext";
import usePageListDetails from "../../PageHooks/usePageListDetails";
import { ItemsContextProvider } from "../../../hooks/dataTableHooks/useItemsContext";
import PrimaryTable from "../../../components/ui/Tables/PrimaryTable/PrimaryTable";
import BaseText from "../../../components/ui/BaseText";
import { getFontAwesomeIcon } from "../../../utils";
import { BaseButton } from "../../../components/ui";
import { useHostName, usePrivilegeCheck } from "../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

interface AdditionalData {
	slug?: string;
	uuid?: string;
	salesOrderStageId?: number[];
	isQuote:boolean;
}

const OrdersPage: React.FC = () => {
	const hostName = useHostName();
	const { isAdmin } = usePrivilegeCheck();
	const navigate = useNavigate();
	const [pageTitle, setPageTitle] = useState("Orders");
	const [_, setOrdersData, exportToCSV, isLoading] = useExportableData();
	const { pageMeta } = usePageListDetails("sales-orders-listing");
	const [hasModalTable, setHasModalTable] = useState<boolean>(true);
	const [shouldRenderResetFilters, setShouldRenderResetFilters] =
		useState(false);

	const [callFromFilter, setCallFromFilter] = useState<boolean>(
		localStorage.getItem("approvalFilter") === "true"
	);
	const [additionalData, setAdditionalData] = useState<AdditionalData>(
		{} as AdditionalData
	);
	const [isAdditionalDataLoaded, setIsAdditionalDataLoaded] = useState(false);

	const slug = "sales-orders";
	const filterSlug = ["test-filter-slug"];
	const hasPermissions =
		pageMeta?.acl["sales-orders"]["navigation-edit-on-commerce"];

	const useApprovalFilter = () => {
		return localStorage.getItem("approvalFilter") === "true";
	};

	// Initialize the data for first load
	useEffect(() => {
		// Custom hook to check if approvalFilter is active
		const isApprovalFilterActive = useApprovalFilter();

		// Set initial additionalData based on the hostName and approvalFilter
		const initialAdditionalData: AdditionalData = {
			slug: hostName.hostName === "COMPASS" ? "customerId" : undefined,
			uuid: hostName.hostName === "COMPASS" ? "2" : undefined,
			salesOrderStageId: isApprovalFilterActive
				? isAdmin
					? [1, 10]
					: [10]
				: undefined,
			isQuote:false
		};

		// Set additionalData and mark it as loaded
		setAdditionalData(initialAdditionalData);
		setIsAdditionalDataLoaded(true);
	}, [hostName.hostName, isAdmin]);
	const handleFiltersChange = (isFilterActive: boolean) => {
		setShouldRenderResetFilters(isFilterActive);
	};

	const handleClearFilters = () => {
		localStorage.removeItem("searchCriteria");
		localStorage.removeItem("activeSortColumns");
		setShouldRenderResetFilters(false);
		navigate(0);
	};

	const handleCallFromFilter = () => {
		const isApprovalFilterActive = localStorage.getItem("approvalFilter");

		if (isApprovalFilterActive) {
			localStorage.removeItem("approvalFilter");
			setAdditionalData((prevData) => {
				const { salesOrderStageId, ...rest } = prevData;
				return rest;
			});
		} else {
			localStorage.setItem("approvalFilter", "true");
			setAdditionalData((prevData) => ({
				...prevData,
				salesOrderStageId: isAdmin ? [1, 10] : [10],
			}));
		}
		setCallFromFilter((prev) => !prev);
	};

	return (
		<div className=" px-[60px] h-full font-omnes-regular">
			<Section additionalClasses="flex justify-between py-6">
				<div className="flex items-center">
					{getFontAwesomeIcon("calculator", "solid")}
					<BaseText
						text={pageTitle}
						additionalClasses="pl-2"
						size="text-22px"
						color="text-navy-500"
						tag="h1"
						fontFamily="font-omnes-medium"
					/>
				</div>
				<div className="flex w-1/3 justify-end items-center">
					{hostName.hostName === "COMPASS" && hasPermissions && (
						<div className="flex">
							<BaseButton
								text="Approvals"
								bgColor={
									callFromFilter ? "bg-primary" : "bg-white"
								}
								fontColor={
									callFromFilter
										? "text-white"
										: "text-primary"
								}
								hoverBackground=""
								buttonSize="h-26px"
								borderColor="border-1 border-primary"
								additionalClasses={`${
									callFromFilter
										? "hover:bg-primaryDefaultHover hover:text-primary "
										: "hover:bg-supply-blue-50"
								}  text-nowrap max-sm:size-8 max-sm:justify-center text-14px px-2 border-1 rounded-full`}
								shape=""
								icon={getFontAwesomeIcon("stamp", "regular")}
								iconBreakpoint="max-sm:pr-0"
								as="button"
								textBreakpoint="max-sm:hidden"
								onClick={handleCallFromFilter}
								fontFamily="font-omnes-medium"
								iconClasses={`${
									callFromFilter
										? "text-white"
										: "text-primary"
								} pr-2`}
							/>
							{shouldRenderResetFilters && (
								<>
									<div className="h-[26px] w-[1px] bg-gray-300 mx-2"></div>
									<BaseButton
										text={"Reset Filters"}
										bgColor={"bg-white"}
										fontColor="text-crimson-700"
										buttonSize="h-26px"
										shape="rounded-full"
										icon={getFontAwesomeIcon(
											"filterSlash",
											"regular"
										)}
										iconBreakpoint="max-sm:pr-0"
										additionalClasses="text-crimson-700 rounded-full hover:bg-crimson-300 border-1 border-crimson-700 text-nowrap max-sm:size-8 max-sm:justify-center text-14px px-2"
										as="button"
										textBreakpoint="max-sm:hidden"
										onClick={handleClearFilters}
										fontFamily="font-omnes-medium"
										iconClasses="pr-2"
									/>
								</>
							)}
						</div>
					)}

					{hostName.hostName === "PRUDENTIAL" && (
						<>
							{isLoading ? (
								<div>
									<FontAwesomeIcon
										className="fa-spin fa-beat-fade"
										icon={faLoader}
									/>
									<span className="pl-2">Downloading</span>
								</div>
							) : (
								<BaseButton
									text="Export"
									bgColor="bg-primary"
									fontColor="text-white"
									hoverBackground="hover:bg-primaryHover"
									buttonSize="w-85px h-26px"
									borderColor="none"
									additionalClasses={`text-nowrap mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
									shape="rounded-full"
									icon={getFontAwesomeIcon(
										"upFromBracket",
										"regular"
									)}
									iconBreakpoint="max-sm:pr-0"
									as="button"
									hasTextBreakpoint={true}
									textBreakpoint="max-sm:hidden"
									// @ts-ignore
									onClick={exportToCSV}
									fontFamily="font-omnes-medium"
									iconClasses="pr-2 text-white"
								/>
							)}
						</>
					)}
				</div>
			</Section>

			{/* Render PrimaryTable only if additionalData is loaded */}
			{isAdditionalDataLoaded && (
				<DataTableProvider pageMeta={pageMeta} pageTitle={pageTitle}>
					<ItemsContextProvider hasModalTable={false} data={[]}>
						<PrimaryTable
							filterSlug={filterSlug}
							callFromFilter={callFromFilter}
							setCallFromFilter={setCallFromFilter}
							pageTitle={""}
							hasModalTable={hasModalTable}
							setPageTitle={setPageTitle}
							setHasModalTable={setHasModalTable}
							slug={slug}
							setData={setOrdersData}
							additionalData={additionalData}
							onFiltersChange={handleFiltersChange}
						/>
					</ItemsContextProvider>
				</DataTableProvider>
			)}
		</div>
	);
};

export default OrdersPage;
