import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

// COMPONENTS
import ShipmentItemsTable from "./forms/ShipmentItemsTable";
import BaseText from "../../../components/ui/BaseText";
import Section from "../../../components/layout/Section";
import BaseButton from "../../../components/ui/BaseButton";
import { LoadingMessage } from "../../../components/misc";
// TYPES, FIELDS, UTILS, HOOKS
import {
	getOrderDetails,
	syncNetsuiteSalesOrderWithToga,
	syncNetsuiteItemFulfillmentWithToga,
	checkIfSalesOrderExists,
} from "./api/ShipmentItemsApi";
import getFontAwesomeIcon from "../../../utils/getFontAwesomeIcon";
const ShipmentItemsPage: React.FC = () => {
	const [data, setData] = useState<any[]>([]);
	const [error, setError] = useState<boolean>(false);
	const query = new URLSearchParams(useLocation().search);
	const internalId = query.get("internalId");
	const [isRefreshed, setIsRefreshed] = useState<boolean>(false);
	const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				//Check if SalesOrder Exists in toga database
				const isExist = await checkIfSalesOrderExists("" + internalId);
				if (!isExist) {
					const response = await syncNetsuiteSalesOrderWithToga(
						"" + internalId
					);
					if (response.salesOrders.syncNetsuiteSalesOrder) {
						//Sync the item fulfillment
						const responseFulfillment =
							await syncNetsuiteItemFulfillmentWithToga(
								"" + internalId
							);
						if (
							!responseFulfillment.itemFulfillments
								.syncNetsuiteItemFulfillmentsForSalesOrder
						) {
							setError(true);
							setData([]);
						}
						const data = await getOrderDetails("" + internalId);
						setData(data?.salesOrders[0].salesOrderItems);
						setError(false);
					} else {
						setError(true);
						setData([]);
					}
				} else {
					const data = await getOrderDetails("" + internalId);
					setData(data?.salesOrders[0].salesOrderItems);
					setError(false);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [internalId, navigate]);

	const handleRefresh = async () => {
		setIsRefreshing(true);
		setIsRefreshed(false);
		const response = await syncNetsuiteSalesOrderWithToga("" + internalId);
		if (response.salesOrders.syncNetsuiteSalesOrder) {
			//Sync the item fulfillment
			const responseFulfillment =
				await syncNetsuiteItemFulfillmentWithToga("" + internalId);
			if (
				!responseFulfillment.itemFulfillments
					.syncNetsuiteItemFulfillmentsForSalesOrder
			) {
				setError(true);
				setData([]);
			}
			const data = await getOrderDetails("" + internalId);
			setData(data?.salesOrders[0].salesOrderItems);
			setError(false);
			setIsRefreshed(true);
		} else {
			setError(true);
			setData([]);
		}
		setIsRefreshing(false);
	};

	const shipmentsUrl = `/shipments?internalId=${internalId}`;

	////////////////////////////////////////////// STYLES ////////////////////////////////////////////
	const containerClassNames =
		"pt-24 px-60px min-h-full max-h-full font-omnes-regular mb-10";
	const sectionClassNames =
		"flex flex-col items-start bg-navy-100 border-1 border-navy-200 rounded-xl min-h-[862px] gap-y-5";
	const additionalSectionClassNames =
		"flex flex-col items-start  pt-0 w-full";
	const thirdSectionClassNames =
		"flex flex-col items-start justify-between w-full";
	const motionDivClasses = "flex items-center justify-center w-full";
	const buttonAdditionalClasses = "py-1 px-3 text-nowrap text-14px";
	//////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<div className={containerClassNames}>
			<Section additionalClasses={sectionClassNames} padding="p-10">
				<Section
					additionalClasses={additionalSectionClassNames}
					padding="py-0"
				>
					<BaseButton
						text="Shipments"
						additionalClasses="hover:text-supply-blue-300 text-supply-blue-500"
						icon={getFontAwesomeIcon("chevronLeft", "regular")}
						iconClasses="text-xl pr-2"
						fontFamily="font-omnes-regular"
						as="link"
						to={shipmentsUrl}
					/>
					<div className="flex w-full">
						<div className="flex flex-col w-full items-start">
							<BaseText
								text={`${error ? "Error" : "Shipment Items"}`}
								additionalClasses="py-4"
								size="text-32px"
								color="text-navy-500"
								tag="h1"
								fontFamily="font-omnes-medium"
							/>
							<BaseText
								text={`${
									error
										? "Error Description : Netsuite Sales Order Sync Failed"
										: "Choose items to include in the shipment."
								}`}
								additionalClasses=""
								size="text-base"
								color="text-navy-500"
								tag="h2"
								fontFamily="font-omnes-regular"
							/>
						</div>
						<div className="flex items-end">
							<BaseButton
								text={`${
									isRefreshed
										? "Refreshed successfully"
										: isRefreshing
										? "Refreshing..."
										: "Refresh"
								}`}
								bgColor={`${
									data.length === 0
										? "bg-navy-200"
										: "bg-white"
								}`}
								borderColor="border-1 border-primary"
								fontColor="text-primary"
								hoverBackground="hover:bg-primaryHoverLight"
								additionalClasses={buttonAdditionalClasses}
								shape="rounded-full"
								icon={
									isRefreshing ? (
										<motion.div
											animate={{
												rotate: 360,
											}}
											transition={{
												repeat: Infinity,
												duration: 1,
												ease: "linear",
											}}
											className="flex items-center"
										>
											{getFontAwesomeIcon(
												"refresh",
												"solid"
											)}
										</motion.div>
									) : (
										getFontAwesomeIcon("refresh", "solid")
									)
								}
								fontFamily="font-omnes-medium"
								iconClasses="flex items-center pr-2 text-xs text-primary"
								iconOrder="first"
								buttonSize="h-7"
								as="button"
								type="submit"
								onClick={handleRefresh}
								isDisabled={data.length === 0}
								disabledButtonClasses="bg-gray-400"
							/>
						</div>
					</div>
				</Section>
				<Section
					additionalClasses={thirdSectionClassNames}
					padding="p-0"
				>
					<AnimatePresence>
						{isLoading ? (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									duration: 0.2,
									ease: "easeInOut",
								}}
								className={motionDivClasses}
							>
								<div className="h-full">
									<LoadingMessage message={""} />
								</div>
							</motion.div>
						) : (
							data?.length > 0 && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.2 }}
									className={motionDivClasses}
								>
									<ShipmentItemsTable data={data} />
								</motion.div>
							)
						)}
					</AnimatePresence>
				</Section>
			</Section>
		</div>
	);
};

export default ShipmentItemsPage;
