// GENERAL
import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// COMPONENTS
import BaseText from "../../../components/ui/BaseText";
import Section from "../../../components/layout/Section";
import BaseButton from "../../../components/ui/BaseButton";
import LogoSvg from "../../../assets/agilant-logo.svg";
import ShipmentsCardTableForm from "../../Shipments/listing/components/forms/ShipmentsCardTableForm/ShipmentsCardTableForm";

// TYPES, FIELDS, UTILS, HOOKS
import getFontAwesomeIcon from "../../../utils/getFontAwesomeIcon";
import { getShipments } from "../../Shipments/api/ShipmentsApi";
import { LoadingMessage } from "../../../components/misc";

const FulfilledShipmentsPage: React.FC = () => {
	const [pageTitle, setPageTitle] = useState<string>("Fulfilled Shipments");
	const query = new URLSearchParams(useLocation().search);
	const internalId = query.get("internalId");

	const [refetchData, setRefetchData] = useState<boolean>(false);

	const [data, setData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const data = await getShipments("" + internalId, true);
				setData(data.itemFulfillments);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [internalId, navigate, refetchData]);

	const shipmentsUrl = `/shipment-items?internalId=${internalId}`;
	const currentShipmentsUrl = `/shipments?internalId=${internalId}`;

	////////////////////////////////////////////// STYLES ////////////////////////////////////////////
	const motionDivClasses = "flex items-center justify-center w-full";
	const outerSectionClasses =
		"pt-24 px-60px min-h-full max-h-full font-omnes-regular mb-10 ";
	const shipmentFormClasses =
		"flex flex-col items-start bg-navy-100 border-1 border-navy-100 rounded-xl min-h-[862px]";
	const logoTitleSectionClasses =
		"flex flex-col items-start pb-0 pt-0 w-full";
	const createFirstShipmentClasses =
		"flex flex-col items-center justify-center w-full h-full pt-20";
	//////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<div className={outerSectionClasses}>
			<Section additionalClasses={shipmentFormClasses} padding="p-10">
				<Section additionalClasses={logoTitleSectionClasses}>
					<img src={LogoSvg} alt="Agilant Solutions Logo." />
					<BaseText
						text={pageTitle}
						additionalClasses="py-4"
						size="text-32px"
						color="text-navy-500"
						tag="h1"
						fontFamily="font-omnes-medium"
					/>

					<div className="flex w-full justify-between">
						<BaseText
							text="Print and review past shipments."
							additionalClasses=""
							size="text-base"
							color="text-navy-500"
							tag="h1"
							fontFamily="font-omnes-regular"
						/>
					</div>
				</Section>

				<Section
					additionalClasses="w-full overflow-scroll"
					padding="p-0"
				>
					<AnimatePresence>
						{isLoading ? (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									duration: 0.2,
									ease: "easeInOut",
								}}
								className={motionDivClasses}
							>
								<div className="h-full">
									<LoadingMessage message={""} />
								</div>
							</motion.div>
						) : data.length === 0 || !data ? (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.2 }}
								className={motionDivClasses}
							>
								<div className={createFirstShipmentClasses}>
									<p className="pb-2.5">
										No fulfilled shipments. Create your
										first shipment.
									</p>
									<BaseButton
										text="Shipment"
										bgColor="bg-primary"
										fontColor="text-white"
										hoverBackground="hover:bg-primaryHover"
										additionalClasses="py-1 px-3 text-nowrap"
										shape="rounded-full"
										icon={getFontAwesomeIcon(
											"plus",
											"regular"
										)}
										as="link"
										to={`${shipmentsUrl}`}
										fontFamily="font-omnes-medium"
										iconClasses="flex items-center pr-2 text-white"
										buttonSize="h-7 w-28"
										fontSize="text-sm"
									/>
								</div>
							</motion.div>
						) : (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.2 }}
								className={motionDivClasses}
							>
								<ShipmentsCardTableForm
									onFetchData={() =>
										setRefetchData(
											(prevState) => !prevState
										)
									}
									data={data}
									isFulfilledShipmentsView={true}
								/>
							</motion.div>
						)}
					</AnimatePresence>
				</Section>
			</Section>
		</div>
	);
};

export default FulfilledShipmentsPage;
