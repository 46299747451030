import React, { ElementType } from "react";
import { Link } from "react-router-dom";

export interface BaseButtonTypes {
	onClick?: (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => void;
	text?: string | JSX.Element;
	bgColor?: string;
	fontColor?: string;
	borderColor?: string;
	buttonSize?: string;
	shape?: string;
	iconOrder?: "first" | "last";
	icon?: JSX.Element | boolean | null;
	iconBreakpoint?: string;
	iconAriaLabel?: string;
	type?: "submit" | "reset" | "button";
	to?: string;
	href?: string;
	tooltip?: string;
	additionalClasses?: string;
	isDisabled?: boolean;
	as?: ElementType;
	hoverBackground?: string;
	hoverUnderline?: boolean;
	hoverBorderColor?: string;
	ariaSelected?: boolean;
	iconClasses?: string;
	textAdditionalClasses?: string;
 	fontSize?: string;
	fontFamily?: string;
	textBreakpoint?: string;
	hoverFontColor?: string;
	disabledButtonClasses?: string;
 }

const BaseButton: React.FC<BaseButtonTypes> = ({
	onClick,
	text,
	fontFamily,
	fontColor,
	fontSize,
	shape,
	buttonSize,
	bgColor,
	borderColor,
	iconOrder,
	icon,
	iconBreakpoint,
	textBreakpoint,
	textAdditionalClasses,
	disabledButtonClasses,
 	additionalClasses,
	type = "button",
	to,
	href,
	tooltip,
	isDisabled = false,
	as: Element = "a",
	hoverBackground,
	hoverUnderline,
	hoverBorderColor,
	hoverFontColor,
	iconAriaLabel,
	iconClasses,
	ariaSelected,
	...props
}) => {
	const buttonClasses = `flex justify-center ${
		iconOrder === "last" ? "flex-row-reverse" : ""
	} items-center ${shape} ${buttonSize} ${additionalClasses} ${
		isDisabled
			? `cursor-not-allowed ${disabledButtonClasses}`
			: `cursor-pointer ${borderColor} ${bgColor} ${hoverBackground} ${hoverBorderColor}`
	}`;
	const textClasses = `${fontFamily} ${fontSize} ${textBreakpoint} ${textAdditionalClasses} ${fontColor} ${hoverFontColor}`;
	const iconClassNames = `${iconClasses} ${iconBreakpoint}  `;

	const handleClick = (
		e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
	) => {
		if (isDisabled) {
			e.preventDefault();
			return;
		}
		onClick?.(e);
	};

	const baseProps = {
		className: buttonClasses,
		onClick: handleClick,
		type: type,
		"aria-disabled": isDisabled ? "true" : "false",
		"data-tooltip": tooltip,
		"aria-label": iconAriaLabel,
		"aria-selected": ariaSelected,
	};

	if (Element === "a") {
		props = { ...baseProps, href: href };
	} else if (Element === "link") {
		Element = Link;
		props = { ...baseProps, to: to };
	} else if (Element === "button") {
		props = { ...baseProps };
	}

	return (
		<Element {...props}>
			{icon && <span className={iconClassNames}>{icon}</span>}
			<div className={textClasses}> {text}</div>
		</Element>
	);
};

export default BaseButton;
