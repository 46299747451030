import { useEffect, useRef, useState } from "react";
import { SearchColumnType } from "../types";
import TableRow from "../TableRow";
import { useItemsContext } from "../../../../hooks/dataTableHooks/useItemsContext";
import PrimaryTableHeader from "./PrimaryTableHeader";
import { TableContentProps } from "../types";
import { Column } from "react-table";
import { usePrivilegeCheck } from "../../../../hooks";

const PrimaryTableContent: React.FC<TableContentProps> = ({
	headerGroups,
	data,
	page,
	getTableProps,
	getTableBodyProps,
	prepareRow,
	activeIndex,
	handleRowClick,
	handleClear,
	setSearchColumn,
	showDetailsBox,
	setResetSearch,
	editingHeader,
	searchText,
	setSearchText,
	setSubmittedSearchText,
	setSortColumn,
	setEditingHeader,
	setHeaderValue,
	columnMappings,
	slug,
	filterApplied,
	onHeaderSortClick,
}) => {
	const [globalTrimActive, setGlobalTrimActive] = useState(false);
	const { searchColumn } = useItemsContext();
	// double bang operator to convert to boolean
	const hasApprovalFilter = !!localStorage.getItem("approvalFilter");
	const { isSupervisor, isAdmin } = usePrivilegeCheck();
	//show alt message for approvals
	const showApprovalsMessage = isAdmin ? hasApprovalFilter : isSupervisor;
	const dataLength = data.length;

	const submittedSearchTextFromLocalStorage = localStorage.getItem(
		"submittedSearchText"
	);
	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const message = `No results for ${searchColumn} "${
		submittedSearchTextFromLocalStorage || ""
	}"`;

	const noApprovalsMessage = `All caught up! No orders pending approval.`;

	const tableClassNames = ` table-auto center w-full transition-transform duration-500 sortable min-w-max relative`;
	const tableContainerClassNames = `${
		dataLength <= 10 ? "h-full max-h-full" : "h-full max-h-full"
	} overflow-auto rounded-lg `;

	const errorMessageContainerClassNames =
		"min-h-[550px] flex justify-center items-center text-xl z-[-1] relative ";

	let searchResultsPillColor;
	if (data.length === 0) {
		searchResultsPillColor = "bg-crimson-700";
	} else {
		searchResultsPillColor = "bg-supply-blue-400";
	}
	``;
	return (
		<div ref={scrollContainerRef} className={tableContainerClassNames}>
			<div
				className={`${
					filterApplied ? "w-[99px] " : "w-[67px]"
				} h-full top-0 bg-gray-50 absolute right-[0px] z-0 border-l-1 border-navy-250 rounded-tr-lg`}
			></div>
			<table className={tableClassNames} {...getTableProps()}>
				<colgroup>
					{headerGroups[0].headers.map(
						(
							column: Column<{
								[key: string]: string | React.ReactElement;
							}>,
							index: number
						) => (
							<col
								key={index}
								style={{
									width:
										column.id === "sticky-column"
											? "64px"
											: "auto",
								}}
							/>
						)
					)}
				</colgroup>
				<PrimaryTableHeader
					setClearSearch={handleClear}
					setSearchColumn={setSearchColumn}
					showSlideMenu={showDetailsBox}
					setResetSearch={setResetSearch}
					headerGroups={headerGroups}
					editingHeader={editingHeader}
					searchText={searchText}
					setSearchText={setSearchText}
					setSubmittedSearchText={setSubmittedSearchText}
					setSortColumn={setSortColumn}
					setEditingHeader={setEditingHeader}
					setHeaderValue={setHeaderValue}
					columnMappings={columnMappings}
					slug={slug}
					searchResultsPillColor={searchResultsPillColor}
					onHeaderSortClick={onHeaderSortClick}
				/>

				<tbody {...getTableBodyProps()}>
					{data.length === 0 ? (
						<tr>
							<td colSpan={headerGroups[0].headers.length}>
								<div
									className={errorMessageContainerClassNames}
								>
									{showApprovalsMessage
										? noApprovalsMessage
										: message}
								</div>
							</td>
						</tr>
					) : (
						page.map((row, index) => (
							<TableRow
								rowUuid={data[index]?.data?.uuid}
								key={row.id}
								row={row}
								prepareRow={prepareRow}
								activeIndex={activeIndex}
								globalTrimActive={globalTrimActive}
							/>
						))
					)}
				</tbody>
			</table>
		</div>
	);
};

export default PrimaryTableContent;
