import React from "react";
import ImageComponent from "../../layout/ImageComponent";
import placeholderImage from "../../../assets/empty-image.png";
import formatCurrency from "../../../utils/formatCurrency";
import formatCustomerName from "../../../utils/formatCustomerName";
import BaseToolTip from "../BaseToolTip";
import getFontAwesomeIcon from "../../../utils/getFontAwesomeIcon";
import { CardTableField, CardTableDetailTypes } from "./types";

const CardTableDetail: React.FC<CardTableDetailTypes> = ({
	value,
	field,
	allFields,
	allValues,
	iconStyle = "solid",
	mobileBreakpoint,
	isDisabled,
	textSize = "text-sm",
	hostName,
	gap,
	maxContainerWidth,
}) => {
	let src;
	if (field && field.hasValueImage && value) {
		src = allValues[field.imageValueKey as string];
	}

	const renderValue = (
		item: CardTableField,
		value: string | boolean | number
	) => {
		if (Array.isArray(value)) {
			return (
				<div
					className={`flex flex-col ${
						isDisabled && item.disabledClasses
					}`}
				>
					<>
						<p
							className={`${textSize} ${
								isDisabled && item.disabledClasses
							}`}
						>
							{value[0]}
						</p>
						<p
							className={`${textSize} ${
								item.secondValueTextClasses
							} ${isDisabled && item.disabledClasses}`}
						>
							{value[1]}
						</p>
					</>
				</div>
			);
		} else if (item.isBoolean) {
			return (
				<p
					className={`${textSize} ${
						isDisabled && item.disabledClasses
					}`}
				>
					{value ? item.truthyValue : item.falsyValue}
				</p>
			);
		} else if (item.isWeight) {
			if (value === "-") {
				return (
					<p
						className={`${textSize} ${
							isDisabled && item.disabledClasses
						}`}
					>
						{`${value}`}
					</p>
				);
			} else {
				return (
					<p
						className={`${textSize} ${
							isDisabled && item.disabledClasses
						}`}
					>
						{`${value} ${item.isWeight}`}
					</p>
				);
			}
		} else if (item.isConcatenated && value && allValues) {
			const secondValue = allFields?.find((singleField) => {
				return singleField.valueKey === item.isConcatenated;
			});

			let secondValueKey = secondValue?.valueKey as keyof typeof value;
			return (
				<p
					className={`${textSize} ${
						isDisabled && item.disabledClasses
					}`}
				>
					{`${value} ${allValues[secondValueKey] ?? ""}`}
				</p>
			);
		} else if (
			item.isCurrency &&
			(typeof value === "string" || typeof value === "number")
		) {
			if (value === "-") {
				return (
					<p
						className={`${textSize} ${
							isDisabled && item.disabledClasses
						}`}
					>
						{value}
					</p>
				);
			} else {
				return (
					<p
						className={`${textSize} ${
							isDisabled && item.disabledClasses
						}`}
					>
						{formatCurrency(value)}
					</p>
				);
			}
		} else if (item.isDimensions && value) {
			const dimensions = allValues[item.valueKey];

			return (
				<p
					className={`${textSize} ${
						isDisabled ? item.disabledClasses : ""
					}`}
				>
					{`${
						item.lengthValueKey
							? dimensions[item.lengthValueKey]
							: "—"
					} x ${
						item.widthValueKey
							? dimensions[item.widthValueKey]
							: "—"
					} x ${
						item.heightValueKey
							? dimensions[item.heightValueKey]
							: "—"
					} ${
						item.unitsValueKey
							? dimensions[item.unitsValueKey]
							: "—"
					}`}
				</p>
			);
		} else if (item.isAddress && value) {
			const address = allValues[item.valueKey];
			const isEmpty = Object.values(address).every((val) => val === "");

			if (isEmpty) {
				return <p className={`${textSize}`}>Not Provided</p>;
			} else {
				return (
					<>
						<p
							className={`${textSize} ${
								isDisabled ? item.disabledClasses : ""
							}`}
						>
							{`${
								item.addresseeValueKey
									? address[item.addresseeValueKey]
									: ""
							}, Attn: ${
								item.attentionValueKey
									? address[item.attentionValueKey]
									: ""
							}, ${
								item.line1ValueKey
									? address[item.line1ValueKey]
									: ""
							}, ${
								item.line2ValueKey
									? address[item.line2ValueKey]
									: ""
							}, ${
								item.cityValueKey
									? address[item.cityValueKey]
									: ""
							}, ${
								item.stateValueKey
									? address[item.stateValueKey]
									: ""
							}, ${
								item.zipValueKey
									? address[item.zipValueKey]
									: ""
							}, ${
								item.countryValueKey
									? address[item.countryValueKey]
									: ""
							}`}
						</p>
					</>
				);
			}
		} else if (
			item.isCustomerName &&
			typeof value === "string" &&
			hostName
		) {
			return (
				<p
					className={`${textSize} ${
						isDisabled && item.disabledClasses
					}`}
				>
					{formatCustomerName(value, hostName)}
				</p>
			);
		} else if (value === "N/A" || value === null || value === undefined) {
			return <p className={`${textSize}`}>Not Provided</p>;
		} else {
			return (
				<p
					className={`${textSize} ${
						isDisabled && item.disabledClasses
					}`}
				>
					{value}
				</p>
			);
		}
	};

	return field && value ? (
		<div
			key={field.id}
			className={`flex ${maxContainerWidth} ${gap} ${field.containerWidth}`}
		>
			<div className={`flex w-full ${field.flexDirection} `}>
				{/* LABEL LEFT ICON */}
				{field.labelIcon === "true" && field.label && iconStyle && (
					<div className={`flex pr-2 size-12 items-center`}>
						{getFontAwesomeIcon(
							field.label,
							iconStyle as "solid" | "regular" | "light"
						)}
					</div>
				)}

				{/* LABEL */}
				{field.label && (
					<div
						className={`flex w-full text-nowrap ${textSize} ${field.labelTextClasses}`}
					>
						<p>{field.label}</p>
					</div>
				)}
				{/* LABEL TOOL TIP*/}
				{field.labelToolTip === true && field.label && (
					<BaseToolTip
						message="A projected cost based on current data."
						icon="circleInfo"
						toolTipPlacement="right"
					/>
				)}

				<div className={`flex ${field.valueTextClasses}`}>
					{/* VALUE ICON ONE */}
					{field.hasValueLeftIconOne && iconStyle && (
						<div className={`px-1 ${field.valueIconClassesOne}`}>
							{getFontAwesomeIcon(
								field.hasValueLeftIconOne,
								field.valueIconOneWeight as
									| "solid"
									| "regular"
									| "light"
							)}
						</div>
					)}

					{/* VALUE ICON TWO */}
					{field.hasValueLeftIconTwo && iconStyle && (
						<div className={`px-1 ${field.valueIconClassesTwo}`}>
							{getFontAwesomeIcon(
								field.hasValueLeftIconTwo,
								field.valueIconTwoWeight as
									| "solid"
									| "regular"
									| "light"
							)}
						</div>
					)}

					{/* VALUE IMAGE */}
					{field.hasValueImage && (
						<div className="pr-2">
							<ImageComponent
								imageUrl={src || placeholderImage}
								altText={""}
								className={field.imageClasses}
							/>
						</div>
					)}

					{/* VALUE */}
					{renderValue(field, value)}

					{/* VALUE RIGHT ICON */}
					{field.hasValueRightIcon && iconStyle && (
						<div className="pl-6">
							{getFontAwesomeIcon(
								field.hasValueRightIcon,
								iconStyle as "solid" | "regular" | "light"
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export default CardTableDetail;
