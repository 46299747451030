import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { BaseButton, BaseInput, BaseText } from "../../ui";
import NotesList from "./NotesList";
import DUMMYNOTESFIELD from "./DUMMYNOTESFIELD.json";
import { AddNotesFormProps, FormValues } from "./types";
import {updateSalesOrderNotes} from "../../../pages/Orders/listing/api/OrdersApi";
const AddNotesForm: React.FC<AddNotesFormProps> = ({
	notesData,
	openViewAllNotes,
	showViewAllButton,
	title,
	showBottomNoteCount,
	notesPlacementBottom,
	refetchData,
	notesListMaxHeight,
	salesOrderUuid,
	addNoteButtonStyles,
}) => {
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const userData = localStorage.getItem("user");
	const userUuid = userData ? JSON.parse(userData).uuid : null;

	// Form setup
	const methods = useForm<FormValues>({
		mode: "onChange",
		defaultValues: {
			note: "",
		},
	});
	const { handleSubmit } = methods;

	// On submit
	const onSubmit = (formData: Record<string, any>) => {
		updateSalesOrderNotes(salesOrderUuid, userUuid, formData.note, "admin");
		if (refetchData) {
			refetchData();
		}
	};

	// Classes
	const parentContainer = "flex flex-col items-start w-full h-full";
	const titleViewAllContainer =
		"flex flex-row gap-2 items-center justify-center pb-3";
	const badgeClasses = "p-2 h-6 flex items-center rounded-lg bg-indigo-100";
	const notesAndFormContainer = `flex flex-col w-full h-full ${
		!notesPlacementBottom ? "justify-between " : ""
	}`;
	const cancelPublishButtonContainer = "w-full flex justify-end gap-3 pb-5";

	return (
		<div className={parentContainer}>
			<div className={titleViewAllContainer}>
				{title}
				{showViewAllButton && openViewAllNotes && (
					<BaseButton
						text="View All"
						textAdditionalClasses="text-primary text-sm hover:underline"
						onClick={() => openViewAllNotes()}
					/>
				)}
			</div>
			<div className={notesAndFormContainer}>
				{!notesPlacementBottom && (
					<div className="w-full pb-4">
						<NotesList
							notesData={notesData}
							badgeClasses={badgeClasses}
							badgeText={
								<BaseText
									text={"Admin"}
									fontFamily="font-omnes-medium"
									tag="p"
									size="text-sm"
									color="text-indigo-700"
								/>
							}
							maxHeightClasses={notesListMaxHeight}
						/>
					</div>
				)}

				{!isEditMode ? (
					<>
						<BaseButton
							text="Add note here..."
							buttonSize="w-full h-5"
							bgColor="bg-white"
 							additionalClasses={`rounded-lg p-2 py-4 ${addNoteButtonStyles}`}
							textAdditionalClasses="w-full text-left text-sm text-gray-500 "
							onClick={() => setIsEditMode(true)}
							as="button"
						/>
					</>
				) : (
					<FormProvider {...methods}>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="w-full"
						>
							<BaseInput
								field={DUMMYNOTESFIELD.DUMMYNOTESFIELD[0]}
							/>
							<div className={cancelPublishButtonContainer}>
								<BaseButton
									text="Cancel"
									textAdditionalClasses="text-primary text-sm hover:underline"
									onClick={() => setIsEditMode(false)}
									as="button"
								/>
								<BaseButton
									text="Publish"
									bgColor="bg-primary"
									additionalClasses="rounded-xl px-2"
									textAdditionalClasses="text-sm text-white"
									onClick={handleSubmit(onSubmit)}
									hoverBackground="hover:bg-primaryHover"
									as="button"
								/>
							</div>
						</form>
					</FormProvider>
				)}

				{notesPlacementBottom && (
					<div className="w-full pb-4">
						<NotesList
							notesData={notesData}
							badgeClasses={badgeClasses}
							badgeText={
								<BaseText
									text={"Admin"}
									fontFamily="font-omnes-medium"
									tag="p"
									size="text-sm"
									color="text-indigo-700"
								/>
							}
							maxHeightClasses={notesListMaxHeight}
						/>
					</div>
				)}
				{showBottomNoteCount && (
					<div className="flex w-full justify-start">
						<BaseText
							text={`${notesData.length} note(s)`}
							fontFamily="font-omnes-regular"
							tag="h1"
							size="text-sm"
							color="text-gray-500"
							additionalClasses=""
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default AddNotesForm;
