// GENERAL
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";

// COMPONENTS
import ExpandableCellContent from "../../../../components/ui/Tables/ExpandableCellContent";
import ImageComponent from "../../../../components/layout/ImageComponent";
import { BasicTable } from "../../../../components/ui/Tables";
import { BaseButton } from "../../../../components/ui";

// TYPES, FIELDS, UTILS, HOOKS
import formatCurrency from "../../../../utils/formatCurrency";
import placeholderImage from "../../../../assets/empty-image.png";
import { getFontAwesomeIcon } from "../../../../utils";
import {
	ShipmentItemsTableProps,
	ShipmentOrderProps,
	FormData,
	ItemsErrors,
} from "../types";

const ShipmentItemsTable: React.FC<ShipmentItemsTableProps> = ({ data }) => {
	const navigate = useNavigate();
	const [rowsClicked, setRowsClicked] = useState<string[]>([]);
	const [rowsDisabled, setRowsDisabled] = useState<string[]>([]);
	const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
	const query = new URLSearchParams(useLocation().search);
	const internalId = query.get("internalId");


	////////////////////////////////////////// FORM SETUP ///////////////////////////////////////////

	const {
		control,
		handleSubmit,
		setValue,
		setError,
		formState: { errors },
	} = useForm({
		defaultValues: {
			items:
				data?.map((order: ShipmentOrderProps, index: number) => ({
					index: index,
					uuid: order.uuid,
					quantity: order.qtyCommitted,
					isSelected: false,
				})) ?? [],
		},
	});

	const itemsErrors = errors.items as ItemsErrors;

	/////////////////////////////////////////////// STYLES ///////////////////////////////////////////
	const manufacturerColumnClasses = `flex items-center text-sm relative font-omnes-regular font-light max-w-[90%]`;
	const descriptionColumnClasses = `flex items-center justify-between text-sm font-omnes-regular font-light break-words`;
	const quantityColumnsClasses = `flex flex-col items-center text-sm justify-center relative font-omnes-regular font-light w-full`;
	const numberColumnsClasses = `flex items-center justify-end text-sm relative font-omnes-regular font-light`;
	const priceColumnClasses = `flex items-center text-sm relative font-omnes-regular font-light`;
	const errorInputClasses = `border-crimson-700`;
	const errorMessageClasses = `text-xs text-crimson-700 absolute top-[22px] left-3`;
	const buttonAdditionalClasses = "py-1 px-3 text-nowrap text-14px";
	const checkBoxClasses = `border-1 border-navy-200 size-4 cursor-pointer accent-supply-blue-400`;
	const quantityInputClasses = `border-1 rounded text-right px-2 w-20`;
	const partNumberColumnClasses = `flex items-center justify-start w-fit min-w-40`;

	/////////////////////////////////////// SELECT TABLE ROW LOGIC  /////////////////////////////////

	const toggleSelectAll = (isChecked: boolean) => {
		setSelectAllChecked?.(isChecked);
		if (isChecked) {
			const allItemsSelected =
				data
					?.filter(
						(order: ShipmentOrderProps) =>
							!rowsDisabled.includes(order.uuid as string)
					)
					.map((order: ShipmentOrderProps) => ({
						uuid: order.uuid as string,
						quantity: order.quantity,
						isSelected: true,
					})) ?? [];
			setValue("items", allItemsSelected);
			setRowsClicked?.(
				data
					.filter(
						(order: ShipmentOrderProps) =>
							!rowsDisabled.includes(order.uuid as string)
					)
					.map((order: any) => order.uuid)
			);
		} else {
			setValue("items", []);
			setRowsClicked?.([]);
		}
	};

	const handleCheckboxClick = (
		event: React.ChangeEvent<HTMLInputElement>,
		uuid: string
	) => {
		const isChecked = event.currentTarget.checked;

		setRowsClicked?.((prevRows) => {
			if (isChecked) {
				return prevRows.includes(uuid) ? prevRows : [...prevRows, uuid];
			} else {
				setSelectAllChecked?.(false);
				return prevRows.filter((rowUuid) => rowUuid !== uuid);
			}
		});
	};

	////////////////////////////////////////// SUBMIT FORM LOGIC /////////////////////////////////////

	const onSubmit = (formData: FormData) => {
		const selectedItems = rowsClicked.map((rowUuid) => {
			const itemIndex = rowUuid as string;
			const quantity = formData.items[itemIndex];
			const itemData = data?.find(
				(order: ShipmentOrderProps) => order.uuid === itemIndex
			);
			return {
				uuid: rowUuid,
				quantity: quantity,
				itemData: itemData,
				partNumber: itemData?.item?.partNumber,
			};
		});

		let hasError = false;
		selectedItems.forEach((item) => {
			if (Number(item.quantity) === 0) {
				hasError = true;
				setError(`items.${item.uuid}`, {
					type: "manual",
					message: "Cannot be 0.",
				});
			} else if (
				Number(item.quantity) > Number(item.itemData?.qtyCommitted)
			) {
				hasError = true;
				setError(`items.${item.uuid}`, {
					type: "manual",
					message: "Exceeds limit.",
				});
			} else if (!/^\d+$/.test(item.quantity)) {
				hasError = true;
				setError(`items.${item.uuid}`, {
					type: "manual",
					message: "Invalid character.",
				});
			}
		});

		if (hasError) {
			return;
		} else {
			localStorage.setItem(
				"selectedItems",
				JSON.stringify(selectedItems)
			);
			navigate(`/edit-shipment?internalId=${internalId}`);
		}
	};

	/////////////////////////////////////// ITEMS TABLE FORMATTING ///////////////////////////////////
	const tableData = React.useMemo(() => {
		return (data ?? []).map((order: ShipmentOrderProps, index: number) => {
			let isDisabled = false;

			if (Number(order?.qtyCommitted) === 0) {
				isDisabled = true;
				setRowsDisabled((prevRows) => {
					return prevRows.includes(order.uuid as string)
						? prevRows
						: [...prevRows, order.uuid];
				});
			}

			return {
				isDisabled: isDisabled,
				col1: (
					<div
						className={`flex ${
							isDisabled ? "justify-end" : "justify-between "
						}`}
					>
						{isDisabled === false ? (
							<input
								type="checkbox"
								className={checkBoxClasses}
								id={`checkbox-${order.uuid}`}
								data-row-uuid={order.uuid}
								name="select-item"
								onChange={(event) =>
									handleCheckboxClick(
										event,
										order.uuid as string
									)
								}
								checked={rowsClicked?.includes(
									order.uuid as string
								)}
							/>
						) : (
							<></>
						)}
						<label htmlFor="select-item">{index + 1}</label>
					</div>
				),
				col2: (
					<div className={partNumberColumnClasses}>
						<ImageComponent
							imageUrl={placeholderImage}
							altText=""
							className=""
							width={20}
							height={10}
						/>
						<div className="pl-2 text-wrap">
							{order?.item?.partNumber || "—"}
						</div>
					</div>
				),
				col3: order?.item?.skuNumber || "—",
				col4: order?.item?.upc || "—",
				col5: (
					<>
						{order?.item?.manufacturer?.name &&
						order?.item?.manufacturer ? (
							<ExpandableCellContent
								columnData={
									order?.item?.manufacturer?.name || "—"
								}
								columnDataLength={15}
								onHoverColumnDataLength={10}
								minWidth="w-36"
								notShortenedClasses={manufacturerColumnClasses}
							/>
						) : (
							"—"
						)}
					</>
				),
				col6: (
					<div>
						<ExpandableCellContent
							columnData={order?.item?.description || "—"}
							columnDataLength={55}
							onHoverColumnDataLength={40}
							minWidth="w-400px"
							notShortenedClasses={descriptionColumnClasses}
						/>
					</div>
				),
				col7: order?.item?.inventoryType || "—",
				col8: (
					<div className={`${numberColumnsClasses}`}>
						{Number(order?.quantity)}
					</div>
				),
				col9: (
					<div className={`${numberColumnsClasses}`}>
						{Number(order?._qtyFulfilled)}
					</div>
				),
				col10: (
					<div className={`${numberColumnsClasses}`}>
						{Number(order?.qtyCommitted)}
					</div>
				),
				col11: (
					<div
						className={`${priceColumnClasses} flex justify-between`}
					>
						<span className="pr-2 text-xs">
							{getFontAwesomeIcon("dollar", "regular")}
						</span>
						<span>{formatCurrency(order?.price as string)}</span>
					</div>
				),
				col12: (
					<div
						className={`${priceColumnClasses} flex justify-between`}
					>
						<span className="pr-2 text-xs">
							{getFontAwesomeIcon("dollar", "regular")}
						</span>
						<span>
							{formatCurrency(order?._extPrice as string)}
						</span>
					</div>
				),
				col13: (
					<div className={`${quantityColumnsClasses}`}>
						{isDisabled === false && order.uuid ? (
							<>
								<Controller
									name={`items.${order.uuid}`}
									control={control}
									defaultValue={`${Number(
										order?.qtyCommitted
									)}`}
									render={({ field }) => (
										<input
											type="text"
											onFocus={(e) => e.target.select()}
											className={`${quantityInputClasses} ${
												itemsErrors?.[order.uuid]
													? errorInputClasses
													: "border-navy-200"
											}`}
											id={`items.${order.uuid}`}
											{...field}
											placeholder="e.g. 1"
										/>
									)}
								/>

								{itemsErrors?.[order.uuid] && (
									<p className={errorMessageClasses}>
										{itemsErrors?.[order.uuid].message}
									</p>
								)}
							</>
						) : (
							<div className="flex w-full justify-end pr-2">
								—
							</div>
						)}
						<label
							htmlFor={`items.${order.uuid}`}
							className="sr-only"
						>
							{isDisabled
								? `Quantity input for ${order.item?.partNumber} is disabled`
								: `Quantity for ${order.item?.partNumber}`}{" "}
						</label>
					</div>
				),
			};
		});
	}, [data, rowsClicked, selectAllChecked, errors, rowsDisabled]);

	const defaultHeaderData = React.useMemo(() => {
		return [
			{
				id: "col1",
				Header: (
					<div className="flex justify-between">
						<div className="flex items-center pr-2">
							<input
								type="checkbox"
								name="select-all"
								className={checkBoxClasses}
								onChange={() =>
									toggleSelectAll(!selectAllChecked)
								}
								checked={selectAllChecked}
							/>
						</div>
						<label htmlFor="select-all">Line</label>
					</div>
				),
				headerClasses: "text-right",
			},
			{ id: "col2", Header: "Part Number", headerClasses: "text-left" },
			{ id: "col3", Header: "SKU", headerClasses: "text-left" },
			{ id: "col4", Header: "UPC", headerClasses: "text-left" },
			{ id: "col5", Header: "Manufacturer", headerClasses: "text-left" },
			{ id: "col6", Header: "Description", headerClasses: "text-left" },
			{ id: "col7", Header: "Serialized", headerClasses: "text-left" },
			{ id: "col8", Header: "Ordered", headerClasses: "text-right" },
			{ id: "col9", Header: "Fulfilled", headerClasses: "text-right" },
			{ id: "col10", Header: "Committed", headerClasses: "text-right" },
			{ id: "col11", Header: "Unit Price", headerClasses: "text-right" },
			{ id: "col12", Header: "Ext. Price", headerClasses: "text-right" },
			{ id: "col13", Header: "Quantity", headerClasses: "text-right" },
		];
	}, [data, rowsClicked, selectAllChecked]);

	//////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="w-full">
			<BasicTable
				data={tableData}
				headers={defaultHeaderData}
				hasHeaderSpan={false}
				tableBottomBorderClasses="border-b-1 border-navy-250"
				tableContainerWidth="w-full overflow-x-scroll"
				tableWidth={"w-full"}
				quantityLabel="items"
				quantityLabelClasses="text-base"
				tableContainerHeight="max-h-[500px]"
				headerBackgroundColor="bg-navy-100"
				headerTextColor="text-black"
				headerFontSize="text-sm"
				headerBorderColor="border-navy-250"
				headerBorderWidth="border-1"
				headerFontFamily="font-omnes-regular"
				tableBorderColor="border-transparent"
				rowBackgroundColor="bg-white"
				rowHoverBackgroundColor="bg-supply-blue-50"
				rowBorderWidth="border-1"
				rowBorderColor="border-navy-250"
				rowDisabledClasses="bg-navy-200 text-navy-400"
				rowHoverCursor=""
				cellFontSize="text-sm"
				cellPadding="px-2 py-3.5"
			/>
			<div className="flex w-full justify-end">
				<BaseButton
					text={`Next`}
					bgColor={`${
						rowsClicked.length === 0 ? "bg-navy-200" : "bg-primary"
					}`}
					fontColor="text-white"
					hoverBackground="hover:bg-primaryHover"
					additionalClasses={buttonAdditionalClasses}
					shape="rounded-full"
					icon={getFontAwesomeIcon("rightLong", "solid")}
					fontFamily="font-omnes-medium"
					iconClasses="flex items-center pl-2 text-xs text-white"
					iconOrder="last"
					buttonSize="h-7 w-28"
					as="button"
					type="submit"
					onClick={handleSubmit(onSubmit)}
					isDisabled={rowsClicked.length === 0}
					disabledButtonClasses="bg-gray-400"
				/>
			</div>
		</form>
	);
};

export default ShipmentItemsTable;
