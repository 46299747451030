import React from "react";

interface BaseContainerTypes {
	children: React.ReactNode;
	columnContainerClasses?: string;
	columnShape?: "rounded-full" | "semi-rounded" | "none";
	borderColor?: string;
	backgroundColor?: string;
	additionalClasses?: string;
	isDisabled?: boolean;
}

const BaseContainer: React.FC<BaseContainerTypes> = ({
	children,
	columnContainerClasses,
	columnShape,
	borderColor,
	backgroundColor,
	additionalClasses,
	isDisabled = false,
}) => {
	let containerShapeClasses;

	if (columnShape === "rounded-full") {
		containerShapeClasses = "rounded-[calc(1.5rem-1px)]";
	} else if (columnShape === "semi-rounded") {
		containerShapeClasses = "rounded-[calc(1.5rem-14px)]";
	} else {
		containerShapeClasses = "rounded-none";
	}

	return (
		<div
			className={`${columnContainerClasses} ${containerShapeClasses} border-1 border-${borderColor} ${backgroundColor} ${
				isDisabled && "opacity-50"
			} ${additionalClasses}`}
		>
			{children}
		</div>
	);
};

export default BaseContainer;
