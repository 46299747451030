import React, { Fragment, ReactNode } from "react";
import CardTableDetail from "./CardTableDetail";
import { getFontAwesomeIcon } from "../../../utils";
import BaseButton from "../BaseButton";
import { BaseCardTableTypes } from "./types";
const CardTable: React.FC<BaseCardTableTypes> = ({
	dataItem,
	fields,
	cardContainerClasses,
	cardShape,
	borderColor,
	backgroundColor,
	additionalClasses,
	isDisabled = false,
	hasSecondRow,
	firstRowContainerClasses,
	secondRowContainerClasses,
	hasCheckBox,
	checkBoxClasses,
	hasStatusIcon,
	hasEditButton,
	hasDeleteButton,
	hasDuplicateButton,
	statusIconStyle,
	statusIconClasses,
	editButtonClasses,
	deleteButtonClasses,
	duplicateButtonClasses,
	buttonContainerClasses,
	onDeleteClick,
	onEditClick,
	onDuplicateClick,
	onMouseEnter,
	onMouseLeave,
	formatDataFunction,
}) => {
	const cardTableFirstRowContent = fields[0].firstRow.map(
		(field: any, index: number) => {
			let value = dataItem[field.valueKey];

			if (formatDataFunction) {
				value = formatDataFunction(value, field.valueKey);
			}

			return (
				<Fragment key={field.id}>
					<CardTableDetail
						field={field}
						value={value}
						allValues={dataItem}
						gap="gap-x-5"
						maxContainerWidth="max-w-96 min-w-20"
					/>
				</Fragment>
			);
		}
	);

	const cardTableSecondRowContent = fields[1].secondRow.map(
		(field: any, index: number) => {
			const value = dataItem[field.valueKey];
			return (
				<div key={field.id}>
					<CardTableDetail field={field} value={value} gap="px-6" />
				</div>
			);
		}
	);

	return (
		<div
			className={`${cardContainerClasses} ${cardShape} border-1 border-${borderColor} ${backgroundColor} ${
				isDisabled && "opacity-50"
			} ${additionalClasses}`}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{hasCheckBox && (
				<div className="flex justify-between px-2.5 items-start min-w-24">
					{hasCheckBox && <>{hasCheckBox}</>}
					<div className={statusIconClasses}>
						{hasStatusIcon &&
							getFontAwesomeIcon(
								hasStatusIcon,
								statusIconStyle as "solid" | "regular" | "light"
							)}
					</div>
				</div>
			)}
			<div className="flex flex-col w-full">
				<div className={`${firstRowContainerClasses}`}>
					{cardTableFirstRowContent}
				</div>
				{hasSecondRow && (
					<div className={`${secondRowContainerClasses}`}>
						{cardTableSecondRowContent}
					</div>
				)}
			</div>
			{(hasEditButton || hasDuplicateButton || hasDeleteButton) && (
				<div className={`flex min-w-20 ${buttonContainerClasses}`}>
					{hasEditButton && (
						<>
							<BaseButton
								onClick={onEditClick}
								buttonSize="h-10"
								isDisabled={true}
								icon={
									<div
										className={`flex ${editButtonClasses}`}
									>
										{getFontAwesomeIcon("edit", "regular")}
									</div>
								}
							/>
						</>
					)}
					{hasDuplicateButton && (
						<>
							<BaseButton
								onClick={onDuplicateClick}
								buttonSize="h-10"
								icon={
									<div
										className={`flex ${duplicateButtonClasses}`}
									>
										{getFontAwesomeIcon(
											"squarePlus",
											"regular"
										)}
									</div>
								}
							/>
						</>
					)}
					{hasDeleteButton && (
						<>
							<BaseButton
								buttonSize="h-10"
								onClick={onDeleteClick}
								icon={
									<div
										className={`flex ${deleteButtonClasses}`}
									>
										{getFontAwesomeIcon("trash", "regular")}
									</div>
								}
							/>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default CardTable;
