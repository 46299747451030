// @ts-nocheck
//FIXME: fix the types in the api
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Field } from "../pages/Orders/api/OrdersApi";
import { getQueryParams } from "../utils/getQueryParams";
// import { kebabToCamel } from "../utils/kebabToCamel";
// Create a new event
export const UserLoginRequiredEvent = new Event("UserLoginRequired");

function kebabToCamel(kebabString: string): string {
	return kebabString.replace(/-([a-z])/g, function (g) {
		return g[1].toUpperCase();
	});
}
export default kebabToCamel;

const fullHostName = window.location.hostname.toUpperCase();
const hostName = fullHostName.split(".");
const host = "VITE_API_" + hostName[0];
let base_url = import.meta.env[host];
if (base_url == undefined) {
	base_url = import.meta.env.VITE_API;
}

export interface OptionsType {
	transactionId?: string;
	fields?: (
		| string
		| Field
		| { location: string[]; shipToAddress: string[] }
	)[];
	sort?: any;
	slug?: string;
	id?: string;
	join?: Array<{ [key: string]: { [key: string]: string } }>;
	where?: { [key: string]: any; and?: any[]; or?: any[] };
	recordsPerPage?: number;
	page?: number;
}
export interface BaseDataItem extends Row {
	index: number;
	slug: string;
	field: string;
	name: string;
	isVisible: boolean;
}

interface Meta {
	depth: number;
	page: number;
	prevPage: number | null;
	nextPage: number;
	totalPageCount: number;
}

interface Data {
	uuid: string;
	manufacturer: string;
	description: string;
	modelNumber: string;
	assetType: string;
}

interface Row {
	data: Data;
	nested: Nested;
}

interface Nested {
	meta: Meta;
	rows: Row[];
}

export interface DataTableDataType {
	meta: Meta;
	data: BaseDataItem[];
	rows: BaseDataItem[];
}
export interface DataTableMetaType {
	fields: Array<{
		field: string;
		slug: string;
	}>;
	table: {
		uuid: string;
		recordsPerPage: number;
		slug: string;
		name: string;
		route: string;
		fields: any;
	} | null;
}

export interface DataTableFieldMetaType {
	field: string;
	index: number;
	isVisible: boolean;
	isSortable: boolean;
	isFilterable: boolean;
	slug: string;
	name: string;
	type: string;
	precision: number;
	recordRoute: string;
	selectIdentifierField: string;
	selectNameField: string;
}

export interface ConditionType {
	"="?: any;
	"<"?: any;
	">"?: any;
}

localStorage.setItem("hostName", hostName[0]);

export const togaApiRequest = async (
	method: "GET" | "POST" | "PUT" | "DELETE",
	route: string,
	payload: object | null = null,
	options: OptionsType = {}
) => {
	let retry;
	let response;
	const isAuthenticationRequest = route.includes("/auth");
	do {
		retry = false;
		const header = await initialize(isAuthenticationRequest);
		options["transactionId"] = uuidv4();
		let url = "";
		if (route.includes("?")) {
			url = base_url + route + "&" + assembleOptions(options);
		} else {
			url = base_url + route + "?" + assembleOptions(options);
		}

		try {
			switch (method) {
				case "GET":
					response = await axios.get(url, {
						headers: header,
						params: payload,
						validateStatus: function (status) {
							return status >= 200 && status < 500;
						},
					});

					break;
				case "POST":
					response = await axios.post(url, payload, {
						headers: header,
						validateStatus: function (status) {
							return status >= 200 && status < 500;
						},
					});
					break;
				case "PUT":
					response = await axios.put(url, payload, {
						headers: header,
						validateStatus: function (status) {
							return status >= 200 && status < 500;
						},
					});
					break;
				case "DELETE":
					response = await axios.delete(url, {
						headers: header,
						params: payload,
						validateStatus: function (status) {
							return status >= 200 && status < 500;
						},
					});
					break;
			}

			if (response.status === 401) {
				if (route.includes("/auth/refresh")) {
					handleLogout();
				} else {
					localStorage.setItem("accessToken", "");
					retry = true;
				}
			}
		} catch (error) {
			Sentry.captureMessage("Api Error", {
				level: "warning",
				tags: {
					errorType: "API",
					authentication: "failed",
				},
				extra: error,
			});
			console.log(error);
			break;
		}
	} while (retry);
	return response ? response.data : null;
};

export const initialize = async (isAuthenticationRequest: boolean) => {
	let isNewUserLoginRequired = false;
	if (!isAuthenticationRequest) {
		do {
			if (
				localStorage.getItem("accessToken") == undefined ||
				localStorage.getItem("accessToken") == ""
			) {
				if (
					localStorage.getItem("refreshToken") == undefined ||
					localStorage.getItem("refreshToken") == ""
				) {
					const response = await togaApiRequest(
						"POST",
						"/auth/public"
					);
					if (response.isSuccess) {
						localStorage.setItem(
							"accessToken",
							response.data.tokens.access
						);
						localStorage.setItem(
							"refreshToken",
							response.data.tokens.refresh
						);
					} else {
						Sentry.captureMessage("Public Token", {
							level: "warning",
							tags: {
								errorType: "API",
							},
							extra: response,
						});
					}
				} else {
					const response = await togaApiRequest(
						"POST",
						"/auth/refresh"
					);
					if (response.isSuccess) {
						localStorage.setItem(
							"accessToken",
							response.data.tokens.access
						);
					} else if (response.status == 401) {
						handleLogout();
						isNewUserLoginRequired = true;
					}
				}
			}
		} while (
			!isNewUserLoginRequired &&
			(localStorage.getItem("accessToken") == undefined ||
				localStorage.getItem("accessToken") == "")
		);
	}

	if (isNewUserLoginRequired) {
		window.dispatchEvent(UserLoginRequiredEvent);
	} else {
		let header: { "Content-Type": string; Authorization?: string } = {
			"Content-Type": "application/json",
		};

		let bearerToken = null;
		if (
			localStorage.getItem("accessToken") !== undefined &&
			localStorage.getItem("accessToken") !== ""
		) {
			bearerToken = localStorage.getItem("accessToken");
		} else if (
			localStorage.getItem("refreshToken") !== undefined &&
			localStorage.getItem("refreshToken") !== ""
		) {
			bearerToken = localStorage.getItem("refreshToken");
		}

		if (bearerToken) {
			header.Authorization = `Bearer ` + bearerToken;
		}

		return header;
	}
};

const handleLogout = () => {
	localStorage.setItem("theme", "togaSupply");
	localStorage.removeItem("accessToken");
	localStorage.removeItem("refreshToken");
	localStorage.removeItem("darkMode");
	localStorage.removeItem("user");
	window.location.href = "/";
	window.location.reload();
};

export function checkUserLoggedIn() {
	if (
		localStorage.getItem("accessToken") === undefined ||
		localStorage.getItem("accessToken") === null
	) {
		return false;
	}

	const jwtParts = localStorage.getItem("accessToken")?.split(".");
	const decodedJwt = jwtParts ? JSON.parse(atob(jwtParts[1])) : null;
	if (decodedJwt.id.hasOwnProperty("client")) {
		if (decodedJwt.id.client.hasOwnProperty("user")) {
			if (decodedJwt.id.client.user.hasOwnProperty("id")) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
}

interface Options {
	fields?: any;
	where?: any;
	join?: any;
	ojoin?: any;
	sort?: any;
	[key: string]: any;
}

function assembleOptions(options: Options): string {
	let queryString = new URLSearchParams();
	for (let optionKey in options) {
		let optionVal = options[optionKey];
		switch (optionKey.toLowerCase()) {
			case "fields":
				queryString.set(
					"fields",
					assembleOptionsFields(optionVal).join(",")
				);
				break;
			case "where":
				queryString.set("where", assembleOptionsWhere(optionVal));
				break;
			case "join":
				queryString.set("join", assembleOptionsJoin(optionVal));
				break;
			case "ojoin":
				queryString.set("ojoin", assembleOptionsJoin(optionVal));
				break;
			case "sort":
				queryString.set("sort", assembleOptionsSort(optionVal));
				break;
			default:
				queryString.set(optionKey, optionVal);
		}
	}
	// Decode URI because URLSearchParams automatically encodes URI components
	return decodeURIComponent(queryString.toString());
}

function assembleOptionsFields(fields: any, prefix = ""): string[] {
	let output: any = [];

	for (let key in fields) {
		let value = fields[key];
		if (typeof value === "object") {
			let newPrefix = prefix + (isNaN(Number(key)) ? key : "") + ".";
			if (newPrefix == ".") {
				newPrefix = "";
			}
			if (newPrefix.slice(-2) === "..") {
				newPrefix = newPrefix.slice(0, -1);
			}
			output = output.concat(assembleOptionsFields(value, newPrefix));
		} else {
			output.push(prefix + value);
		}
	}

	return output;
}

function urlEncode(input: string, encodeSpecialChars: boolean = true): string {
	if (encodeSpecialChars && input) {
		input = input.replace(/[%&?]/g, (match) => {
			return encodeURIComponent(match);
		});
		input = input.replace(/[!'()*\-._~]/g, function (c) {
			return "%" + c.charCodeAt(0).toString(16).toUpperCase();
		});
		return input;
	} else {
		return encodeURIComponent(input);
	}
}

function assembleOptionsWhere(input: Record<string, any>): string {
	let output = [];
	let operator = "and";
	for (let key in input) {
		let value = input[key];
		operator = key.toUpperCase();
		for (let fieldConditions of value) {
			for (let field in fieldConditions) {
				let conditions = fieldConditions[field];
				let childOperator = field.toUpperCase();
				if (childOperator === "AND" || childOperator === "OR") {
					output.push(assembleOptionsWhere(fieldConditions));
				} else {
					for (let comparison in conditions) {
						let conditionValue = conditions[comparison];
						comparison = comparison.replace(/<>/g, "ne");
						comparison = comparison.replace(/>=/g, "ge");
						comparison = comparison.replace(/>/g, "gt");
						comparison = comparison.replace(/<=/g, "le");
						comparison = comparison.replace(/</g, "lt");
						comparison = comparison.replace(/==/g, "eq");
						comparison = comparison.replace(/!=/g, "ne");
						comparison = comparison.replace(/=/g, "eq");
						comparison = comparison.replace(/not/g, "not");
						comparison = comparison.replace(/like/g, "like");
						comparison = comparison.replace(
							/contains/g,
							"contains"
						);
						comparison = comparison.replace(/starts/g, "starts");
						comparison = comparison.replace(/ends/g, "ends");
						comparison = comparison.replace(
							/excludes/g,
							"excludes"
						);

						// Handling for IN & NOT IN
						if (comparison == "in" || comparison == "notin") {
							let inValues = [];
							for (let inValue of conditionValue) {
								inValues.push(urlEncode(inValue));
							}
							conditionValue = inValues.join(":");
						} else {
							conditionValue = urlEncode(conditionValue);
						}

						output.push(`${field}:${comparison}:${conditionValue}`);
					}
				}
			}
		}
	}
	return `(${output.join(`,${operator},`)})`;
}

function assembleOptionsJoin(input: Record<string, any>): string {
	let out = [];
	for (let item of Object.values(input)) {
		let thisJoin = "";
		for (let table in item as Record<string, any>) {
			let value = (item as Record<string, any>)[table];
			thisJoin += table + ":";
			let thisJoinConditions = [];
			for (let fieldA in value as Record<string, any>) {
				let fieldB = (value as Record<string, any>)[fieldA];
				thisJoinConditions.push(fieldA + "=" + fieldB);
			}
			thisJoin += thisJoinConditions.join(";");
		}
		out.push(thisJoin);
	}
	return out.join(",");
}

function assembleOptionsSort(input: any) {
	return Object.keys(input).length === 0 ? "" : input.join(",");
}

export const getDataTableMeta = async (slug: string) => {
	interface Field {
		isCopyable: any;
		settings: Record<string, any>;
		index: number;
		slug: string;
		field: string;
		name: string;
		isVisible: boolean;
	}
	interface OutputType {
		table: {
			uuid: string;
			recordsPerPage: number;
			slug: string;
			name: string;
			route: string;
			sortPrimaryDirection: string;
			sortPrimaryTableViewFieldSlug: string;
			fields: Field[];
		} | null;
		fields: Array<{
			index: number;
			slug: string;
			field: string;
			name: string;
			isVisible: boolean;
		}>;
		nestedTable: any;
	}

	let output: OutputType = {
		table: null,
		fields: [],
		nestedTable: null,
	};

	/* ------------------------------------------ Get Meta ------------------------------------------ */
	let response = await togaApiRequest("GET", "/table-views/meta", null, {
		slug: slug,
	});

	if (!response.isSuccess) {
		return output;
	}

	output = response.data.tableViews.meta;

	/* -------------------------------- Get values for STATUS fields -------------------------------- */
	output.table.fields = await processStatusFields(output.table.fields);

	/*
	// FOR TESTING OUT getDataTableFieldSelectValues()
	const values = await getDataTableFieldSelectValues(output.table.fields[1]);
	console.log(values);
	*/
	console.log(output, "tableMeta");
	return output;
};

async function processStatusFields(fields) {
	let returnFields = [];

	for (const field of fields) {
		if (field.type === "STATUS") {
			const statusResponse = await togaApiRequest(
				"GET",
				"/" + field.recordRoute,
				null,
				{
					fields: ["slug", "name", "colorHex"],
					sort: ["sortOrder"],
				}
			);

			if (!statusResponse.isSuccess) {
				Sentry.captureMessage("processStatusFields", {
					level: "warning",
					tags: {
						errorType: "API",
					},
					extra: statusResponse,
				});
				console.log("something went wrong!");
				continue; // skip to the next iteration if the response is not successful
			}

			// add the status fields to the field object using the slug as the key
			field.values = statusResponse.data[kebabToCamel(field.recordRoute)];
		}
		returnFields.push(field); // add the modified field to the statusFields array
	}

	return returnFields; // return the processed fields
}

/**
DETAILS ON getDataTableData USAGE

getDataTableData(dataTableMeta, nestedPrimaryRecordUuid)

dataTableMeta = The object returned from getDataTableMeta()

nestedPrimaryRecordUuid = UNDEFINED/NULL or <PARENT_UUID>   (see below for usage)

UNDEFINED/NULL
Returns all data, parent and nested, using query string parameters
Returns an array of data objects for each parent row. Each object has a "nestedRows" property which is an array of data objects for each nested row.
Returns an "index" property for each parent data object, and this is the index position in the main parent array.  It should be used for populating the query string when a child/nested attribute is defined, such as the sorting for a child/nested table of only one of the parent records.
QUERY PARAMETER USAGE
	<PARENT-TABLE-SLUG>_page = set the page number of the parent table to load, ie: 1
	<PARENT-TABLE-SLUG>_recordsPerPage = set the records per page of the parent table to return: ie: 5
	<PARENT-TABLE-SLUG>_sort[<SORT_INDEX>] = an array of sort columns for sorting the parent table, the first element is the primary sort, second is secondary sort, and so on.  defaults to ASCending, add a hyphen (-) on the beginning of the slug field for desending.  ie: -dateOrder
		ie: orders_sort[0]=-dateOrder&orders_sort[1]=number
			(this means sorting the orders parent table  = 2) by dateOrder descending and then by number ascending)
	<PARENT-TABLE-SLUG>_<PARENT-FIELD-SLUG> = searches for this value within the field (<PARENT-FIELD-SLUG>) of the parent table (<PARENT-TABLE-SLUG>)

<PARENT_UUID>

Returns data for only this parent UUID
Still returns a structure similar to using NULL, but only focusing on that one parent and the children
Query parameter usage is similar to parent tables but you must include the <INDEX> of the parent row in the query string.
<INDEX> is the numerical index of the nested table for that parent.
	ie, if the parent is showing 5 records and each record has its own nested table.  if you are referring to the 4th table on the page, then the <INDEX> would be 3 b/c it is 0-based
QUERY PARAMETER USAGE
	<CHILD-TABLE-SLUG>_page[<INDEX>] = set the page number of the parent table to load, ie: 1
	<CHILD-TABLE-SLUG>_recordsPerPage[<INDEX>] = set the records per page of the child table to return: ie: 5
	<CHILD-TABLE-SLUG>_sort[<INDEX>][<SORT_INDEX>] = an array of sort columns for sorting the child table, the first element is the primary sort, second is secondary sort, and so on.  defaults to ASCending, add a hyphen (-) on the beginning of the slug field for desending.  ie: -dateOrder
		ie: units_sort[2][0]=-serialNumber&units_sort[2][1]=date
			(this means sorting the units nested table of the 3rd nested table on the page (index = 2) by serialNumber descending and then by date ascending)
	<CHILD-TABLE-SLUG>_<PARENT-FIELD-SLUG>[<INDEX>] = searches for this value within the field (<PARENT-FIELD-SLUG>) of the parent table (<PARENT-TABLE-SLUG>)
*/

// VERSION 2 (10/5/2023 Jeff C)
export const getDataTableData = async (
	dataTableMeta: DataTableMetaType,
	nestedPrimaryRecordUuid: null | string,
	additionalData?: any
) => {
	// get current query params
	const parentTableSlug = dataTableMeta.table?.slug;
	const queryParams = getQueryParams();

	/* ---------------------------------- Assemble Parent API Call ---------------------------------- */
	// define default options
	let options: OptionsType = {
		fields: ["uuid"],
		recordsPerPage: dataTableMeta?.table?.recordsPerPage,
		page: 1,
		sort: [],
	};

	// add the parent fields and build lookupTableViewSlugsByApiField
	let lookupTableViewFieldsBySlug: { [key: string]: string } = {
		uuid: "uuid",
	};

	dataTableMeta?.table?.fields.map((field) => {
		options?.fields?.push(field.tableViewField);
		lookupTableViewFieldsBySlug[field.slug] = field.tableViewField;

		// hyperlink
		if (field.hyperlinkField) {
			options?.fields?.push(field.hyperlinkField);
			lookupTableViewFieldsBySlug[field.slug + "_hyperlink"] =
				field.hyperlinkField;
		}

		// imageUrl
		if (field.imageUrlField) {
			options?.fields?.push(field.imageUrlField);
			lookupTableViewFieldsBySlug[field.slug + "_imageUrl"] =
				field.imageUrlField;
		}
	});

	// add the joins
	if (dataTableMeta?.table?.joins.length) {
		options.join = [];
		options.ojoin = [];
		dataTableMeta.table.joins.map((join) => {
			options[join.type == "OUTER" ? "ojoin" : "join"].push({
				[join.table + "@" + join.alias]: {
					[join.onA]: [join.onB],
				},
			});
		});
		if (options.join.length === 0) {
			delete options.join;
		}
		if (options.ojoin.length === 0) {
			delete options.ojoin;
		}
	}
	// add the page
	if (queryParams[parentTableSlug + "_page"] !== undefined) {
		options.page = Number(queryParams[parentTableSlug + "_page"]);
	}

	// add the recordsPerPage
	if (queryParams[parentTableSlug + "_recordsPerPage"] !== undefined) {
		options.recordsPerPage = Number(
			queryParams[parentTableSlug + "_recordsPerPage"]
		);
	}
	// add the sort (remember, sort is an array)
	if (queryParams.hasOwnProperty(parentTableSlug + "_sort[0]")) {
		// overriding sort requested, loop through each element until one is undefined
		for (
			let i = 0;
			queryParams.hasOwnProperty(parentTableSlug + "_sort[" + i + "]");
			i++
		) {
			const sort = queryParams[parentTableSlug + "_sort[" + i + "]"];
			let sortName = sort.trim();
			let sortDir = "";
			if (sort.startsWith("-") || sort.startsWith("+")) {
				sortName = sort.substring(1);
				sortDir = sort.charAt(0);
			}
			const field = dataTableMeta.table.fields.find(
				(item) => item.slug === sortName
			);
			options.sort.push(sortDir + field.tableViewField);
		}
	} else if (dataTableMeta?.table?.sort.length) {
		// no sort asked for... use default sort
		for (const sort of dataTableMeta.table.sort) {
			options.sort.push(
				(sort.dir == "DESC" ? "-" : "") +
					lookupTableViewFieldsBySlug[sort.slug]
			);
		}
	}

	// add the search
	for (const field of dataTableMeta.table.fields) {
		const candidateSearchParam =
			dataTableMeta?.table?.slug + "_" + field.slug;
		if (queryParams[candidateSearchParam] != undefined) {
			// found a search parameter on this field, add it to the API call
			if (options.where == undefined) {
				options.where = [];
			}
			// @ts-ignore
			if (options.where.and == undefined) {
				// @ts-ignore
				options.where.and = [];
			} // @ts-ignore
			options.where.and.push({
				[field.tableViewField]: {
					contains: queryParams[candidateSearchParam],
				},
			});
		}
	}

	// if we're searching by a nestedPrimaryRecordUuid, then add that to the where clause
	if (nestedPrimaryRecordUuid) {
		if (options.where == undefined) {
			options.where = [];
		}
		// @ts-ignore
		if (options.where.and == undefined) {
			// @ts-ignore
			options.where.and = [];
		} // @ts-ignore
		options.where.and.push({
			[dataTableMeta.table.table + ".uuid"]: {
				like: nestedPrimaryRecordUuid,
			},
		});
	}
	console.log(additionalData, "additionalData");
	/* --------- Add additional conditions to the where clause from the additionalData object ------*/
	if (additionalData && additionalData.slug && additionalData.uuid) {
		if (options.where == undefined) {
			options.where = [];
		}
		// @ts-ignore
		if (options.where.and == undefined) {
			// @ts-ignore
			options.where.and = [];
		} // @ts-ignore
		if (
			additionalData.slug == "catalogId" ||
			additionalData.slug == "customerId" ||
			additionalData.slug == "Items.catalogId"
		) {
			options.where.and.push({
				[additionalData.slug]: {
					like: additionalData.uuid,
				},
			});
		} else {
			options.where.and.push({
				[additionalData.slug + ".uuid"]: {
					like: additionalData.uuid,
				},
			});
		}
	}

	//TODO - ADD 2 SalesOrder Stages here
	if (additionalData.salesOrderStageId) {
		// Create the or conditions based on the array
		const orConditions = additionalData.salesOrderStageId.map(
			(stageId) => ({
				salesOrderStageId: {
					like: stageId.toString(), // Convert to string if necessary
				},
			})
		);

		// Push the or condition into the and array of options.where
		console.log(options.where, "OPTIONS");
		options.where.and.push({
			or: orConditions,
		});

		if (additionalData.userUuid) {
			options.where.and.push({
				"Users_B.uuid": {
					like: additionalData.userUuid,
				},
			});
		}
		// console.log(options.where);
	}

	//TODO - ADD isQuote
	if (!additionalData.isQuote && additionalData.isQuote !== undefined) {
		if (!options.where) {
			options.where = {};
		}
		if (!options.where.and) {
			options.where.and = [];
		}
		options.where.and.push({
			"isQuote": {
				eq: additionalData.isQuote,
			},
		});
	}

	/* ---------------------------------- Fetch Parent Record Data ---------------------------------- */
	const parentResponse = await togaApiRequest(
		"GET",
		dataTableMeta.table.route,
		null,
		options
	);
	if (!parentResponse.isSuccess) {
		Sentry.captureMessage("parentResponse", {
			level: "warning",
			tags: {
				errorType: "API",
			},
			extra: parentResponse,
		});
		console.log(parentResponse, "something went wrong!");
		console.log("something went wrong!");
		return; // early return if parentResponse is not successful
	}

	/* ----------------------------------- Process Each Parent Row ---------------------------------- */
	interface Output {
		meta: Meta;
		rows: Row[];
	}
	let output: OutPut = {
		meta: parentResponse.meta,
		rows: [],
	};

	const parentRows =
		parentResponse.data[
			kebabToCamel(dataTableMeta.table.route.substring(1))
		];

	for (const index in parentRows) {
		const parentRow = parentRows[index];

		let thisOutputParentRow = {
			data: {},
		};

		/* --------------------------------- NESTED TABLE DATA FETCHING --------------------------------- */
		if (dataTableMeta.nestedTable) {
			// there is a nested table for this, so we need to build and submit each nested API call
			const nestedTableSlug = dataTableMeta.nestedTable?.slug;

			thisOutputParentRow.nested = {
				meta: {},
				rows: [],
			};

			/* ---------------------------------- Assemble Nested API Call ---------------------------------- */

			// define default options
			let nestedOptions: OptionsType = {
				fields: ["uuid"],
				recordsPerPage: dataTableMeta.nestedTable.recordsPerPage,
				page: 1,
				sort: [],
			};

			// add the nested fields and build lookupTableViewSlugsByApiField
			let lookupNestedTableViewFieldsBySlug: { [key: string]: string } = {
				uuid: "uuid",
			};
			dataTableMeta.nestedTable.fields.map((field) => {
				nestedOptions.fields.push(field.tableViewField);
				lookupNestedTableViewFieldsBySlug[field.slug] =
					field.tableViewField;
			});

			// add the nested joins
			if (dataTableMeta.nestedTable.joins.length) {
				nestedOptions.ojoin = [];
				dataTableMeta.nestedTable.joins.map((join) => {
					nestedOptions.ojoin.push({
						[join.table + "@" + join.alias]: {
							[join.onA]: [join.onB],
						},
					});
				});
				if (nestedOptions.ojoin.length === 0) {
					delete nestedOptions.ojoin;
				}
			}

			// add the page
			if (
				queryParams[nestedTableSlug + "_page[" + index + "]"] !==
				undefined
			) {
				nestedOptions.page = Number(
					queryParams[nestedTableSlug + "_page[" + index + "]"]
				);
			} else if (queryParams[nestedTableSlug + "_page"] !== undefined) {
				nestedOptions.page = Number(
					queryParams[nestedTableSlug + "_page"]
				);
			}

			// add the recordsPerPage
			if (
				queryParams[
					nestedTableSlug + "_recordsPerPage[" + index + "]"
				] !== undefined
			) {
				nestedOptions.recordsPerPage = Number(
					queryParams[
						nestedTableSlug + "_recordsPerPage[" + index + "]"
					]
				);
			} else if (
				queryParams[nestedTableSlug + "_recordsPerPage"] !== undefined
			) {
				nestedOptions.recordsPerPage = Number(
					queryParams[nestedTableSlug + "_recordsPerPage"]
				);
			}

			// add the sort (remember, sort is an array)
			if (
				queryParams[nestedTableSlug + "_sort[" + index + "][0]"] !==
				undefined
			) {
				// overriding sort requested
				for (
					let i = 0;
					queryParams.hasOwnProperty(
						nestedTableSlug + "_sort[" + index + "][" + i + "]"
					);
					i++
				) {
					const sort =
						queryParams[
							nestedTableSlug + "_sort[" + index + "][" + i + "]"
						];

					let sortSlug = sort.trim();
					let sortDir = "";
					if (sort.startsWith("-") || sort.startsWith("+")) {
						sortSlug = sort.substring(1);
						sortDir = sort.charAt(0);
					}
					nestedOptions.sort.push(
						sortDir + lookupNestedTableViewFieldsBySlug[sortSlug]
					);
				}
			} else if (
				queryParams[nestedTableSlug + "_sort[0]"] !== undefined
			) {
				// overriding sort requested
				for (
					let i = 0;
					queryParams.hasOwnProperty(
						nestedTableSlug + "_sort[" + i + "]"
					);
					i++
				) {
					const sort =
						queryParams[nestedTableSlug + "_sort[" + i + "]"];

					let sortSlug = sort.trim();
					let sortDir = "";

					if (sort.startsWith("-") || sort.startsWith("+")) {
						sortSlug = sort.substring(1);
						sortDir = sort.charAt(0);
					}
					nestedOptions.sort.push(
						sortDir + lookupNestedTableViewFieldsBySlug[sortSlug]
					);
				}
			} else {
				// no sort asked for... use default sort
				for (const sort of dataTableMeta.nestedTable.sort) {
					nestedOptions.sort.push(
						(sort.dir == "DESC" ? "-" : "") +
							lookupNestedTableViewFieldsBySlug[sort.slug]
					);
				}
			}

			// add the search
			for (const field of dataTableMeta.nestedTable.fields) {
				const candidateSearchParam =
					dataTableMeta?.nestedTable?.slug + "_" + field.slug;
				if (
					queryParams[candidateSearchParam + "[" + index + "]"] !==
					undefined
				) {
					// found a search parameter on this field, add it to the API call
					if (nestedOptions.where == undefined) {
						nestedOptions.where = [];
					}
					// @ts-ignore
					if (nestedOptions.where.and == undefined) {
						// @ts-ignore
						nestedOptions.where.and = [];
					} // @ts-ignore
					nestedOptions.where.and.push({
						[field.tableViewField]: {
							contains:
								queryParams[
									candidateSearchParam + "[" + index + "]"
								],
						},
					});
				}
			}

			// add the nested relationships
			for (const parentField in dataTableMeta.nestedTable.relationships) {
				const nestedField =
					dataTableMeta.nestedTable.relationships[parentField];
				if (nestedOptions.ojoin === undefined) {
					nestedOptions.ojoin = [];
				}

				// add the nested join
				nestedOptions.ojoin.push({
					[dataTableMeta.table.table]: {
						[dataTableMeta.table.table + "." + parentField]: [
							dataTableMeta.nestedTable.table + "." + nestedField,
						],
					},
				});

				if (nestedOptions.ojoin.length === 0) {
					delete nestedOptions.ojoin;
				}

				// add the nested where condition for filtering by the parent uuid
				if (nestedOptions.where == undefined) {
					nestedOptions.where = [];
				}

				if (nestedOptions.where.and == undefined) {
					// @ts-ignore
					nestedOptions.where.and = [];
				} // @ts-ignore
				nestedOptions.where.and.push({
					[dataTableMeta.table.table + ".uuid"]: {
						like: parentRow.uuid,
					},
				});
			}

			/* ---------------------------------- Fetch Nested Record Data ---------------------------------- */
			const nestedResponse = await togaApiRequest(
				"GET",
				dataTableMeta.nestedTable.route,
				null,
				nestedOptions
			);
			if (!nestedResponse.isSuccess) {
				Sentry.captureMessage("nestedResponse", {
					level: "warning",
					tags: {
						errorType: "API",
					},
					extra: nestedResponse,
				});
				console.log("something went wrong!");
				return; // early return if nestedResponse is not successful
			}

			/* ----------------------------------- Process Each Nested Row ---------------------------------- */

			const nestedRows =
				nestedResponse.data[
					kebabToCamel(dataTableMeta.nestedTable.route.substring(1))
				];

			thisOutputParentRow.nested.meta = nestedResponse.meta;
			for (const nestedRow of nestedRows) {
				let thisOutputNestedRow = {
					data: {
						uuid: nestedRow.uuid,
					},
				};

				// map the returned parent API fields into the output object
				for (const fieldMeta of dataTableMeta.nestedTable.fields) {
					let fieldValue = null;
					if (fieldMeta.tableViewField.indexOf(".") == -1) {
						fieldValue = nestedRow[fieldMeta.tableViewField];
					} else {
						const [table, field] =
							fieldMeta.tableViewField.split(".");
						fieldValue = nestedRow[table][field];
					}
					thisOutputNestedRow.data[fieldMeta.slug] = fieldValue;
				}

				// populate the nested data within the parent row
				thisOutputParentRow.nested.rows.push(thisOutputNestedRow);
			}
		}

		// map the returned parent API fields into the output object
		thisOutputParentRow.data.uuid = parentRow.uuid;
		for (const fieldMeta of dataTableMeta.table.fields) {
			let fieldValue = null;
			if (fieldMeta.tableViewField.indexOf(".") == -1) {
				fieldValue = parentRow[fieldMeta.tableViewField];
			} else {
				const [table, field] = fieldMeta.tableViewField.split(".");
				fieldValue = parentRow[table][field];
			}
			thisOutputParentRow.data[fieldMeta.slug] = fieldValue;

			// hyperlink
			if (fieldMeta.hyperlinkField) {
				if (fieldMeta.hyperlinkField.indexOf(".") == -1) {
					fieldValue = parentRow[fieldMeta.hyperlinkField];
				} else {
					const [table, field] = fieldMeta.hyperlinkField.split(".");
					fieldValue = parentRow[table][field];
				}
				thisOutputParentRow.data[fieldMeta.slug + "_hyperlink"] =
					fieldValue;
			}

			// imageUrl
			if (fieldMeta.imageUrlField) {
				if (fieldMeta.imageUrlField.indexOf(".") == -1) {
					fieldValue = parentRow[fieldMeta.imageUrlField];
				} else {
					const [table, field] = fieldMeta.imageUrlField.split(".");
					fieldValue = parentRow[table][field];
				}
				thisOutputParentRow.data[fieldMeta.slug + "_imageUrl"] =
					fieldValue;
			}
		}

		output.rows.push(thisOutputParentRow);
	}
	console.log(output, "output");
	return output;
};

/**
 * Fetches values for a SELECT field type.  This should be used for populating dropdowns.
 * Pass the field meta object to this function.
 *	Example:
		const values = await getDataTableFieldSelectValues(output.table.fields[1]);
 * @param slug
 */
export const getDataTableFieldSelectValues = async (
	dataTableFieldMeta: DataTableFieldMetaType
) => {
	if (
		dataTableFieldMeta.type === "SELECT" &&
		dataTableFieldMeta.recordRoute &&
		dataTableFieldMeta.selectIdentifierField &&
		dataTableFieldMeta.selectNameField
	) {
		let values = [];
		let totalPages = 1;
		for (let page = 1; page <= totalPages; ++page) {
			let response = await togaApiRequest(
				"GET",
				"/" + dataTableFieldMeta.recordRoute,
				null,
				{
					recordsPerPage: 1000,
					page: page,
					fields: [
						dataTableFieldMeta.selectIdentifierField,
						dataTableFieldMeta.selectNameField,
					],
					sort: [dataTableFieldMeta.selectNameField],
				}
			);

			if (response.isSuccess) {
				totalPages = response.meta.totalPageCount;

				// add the response data values to the values array, making sure to use id and name as the two values for the key pair
				values = values.concat(
					response.data[
						kebabToCamel(dataTableFieldMeta.recordRoute)
					].map((item) => {
						return {
							id: item[dataTableFieldMeta.selectIdentifierField],
							name: item[dataTableFieldMeta.selectNameField],
						};
					})
				);
			}
		}

		return values;
	}
	return [];
};

export const getPageMeta = async (page: string) => {
	let response = await togaApiRequest("GET", "/pages/meta", null, {
		page: page,
		app: "supply",
	});
	if (!response.isSuccess) {
		Sentry.captureMessage("getPageMeta", {
			level: "warning",
			tags: {
				errorType: "API",
			},
			extra: response,
		});
		console.log("something went wrong!");
		return;
	}
	return response.data.pages.meta;
};

//Get Event Logs data
export const getEventLogs = async (route: string, uuid: string) => {
	const options = {
		route: route,
		uuid: uuid,
	};
	let response = await togaApiRequest(
		"GET",
		"/record-logs/lookup",
		null,
		options
	);
	if (!response.isSuccess) {
		Sentry.captureMessage("recordLogs", {
			level: "warning",
			tags: {
				errorType: "API",
			},
			extra: response,
		});
		console.log("something went wrong!");
		return;
	}
	return response.data.recordLogs.lookup.recordLogs;
};

//Function to check the differences between original and updated payload data
export const checkForDifferences = (originalData, modifiedData) => {
	const differences = {};

	const compareValues = (key, origVal, modVal) => {
		// Handling explicitly for empty strings
		if (modVal === "" && (origVal === undefined || origVal === "")) {
			// If the new value is an empty string and original was also empty or undefined, ignore it
			return undefined;
		} else if (modVal === "" && origVal !== "") {
			// If new value is an empty string and original was not, mark as cleared
			return "__cleared";
		}

		if (Array.isArray(origVal) && Array.isArray(modVal)) {
			if (JSON.stringify(origVal) !== JSON.stringify(modVal)) {
				return modVal; // Return the modified array if different
			}
		} else if (
			typeof origVal === "object" &&
			origVal !== null &&
			typeof modVal === "object" &&
			modVal !== null
		) {
			const diff = compareObjects(origVal, modVal);
			if (Object.keys(diff).length > 0) {
				return diff;
			}
		} else if (origVal !== modVal) {
			return modVal;
		}
		return undefined;
	};

	const compareObjects = (orig, mod) => {
		const diffs = {};
		const allKeys = new Set([...Object.keys(orig), ...Object.keys(mod)]);
		allKeys.forEach((key) => {
			const origVal = orig[key];
			const modVal = mod[key];
			const result = compareValues(key, origVal, modVal);
			if (result !== undefined) {
				diffs[key] = result;
			}
		});
		return diffs;
	};

	// Compare the top-level objects
	return compareObjects(originalData, modifiedData);
};
