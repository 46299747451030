const formatShipmentData = (
	data: { [key: string]: any },
	internalId: string,
	tableFormData?: { [key: string]: any }[],
	addressUuid?: string,
	setError?: any,
	clearErrors?: any
) => {
	// iterate over selected item data and format serial numbers for serialized items
	// payload requires specific structure for serialized items
	const updatedItems = tableFormData?.flatMap((item: any) => {
		let formattedSerialNumbers: { [key: string]: any }[] = [];

		if (item?.itemData?.item?.inventoryType !== "NON_SERIALIZED") {
			if (Number(item.quantity) > 1) {
				// find data related to serial numbers
				const serialNumberEntries = Object.entries(data).filter(
					([key]) => key.startsWith("partNumber:")
				);

				// create an array with the partNumber in first element, and the serial numbers in the second
				const serialNumbers = serialNumberEntries.map(
					([key, value]) => {
						const partNumber = key.split("partNumber:")[1];
						return { partNumber, serialNumber: value };
					}
				);

				// find the serial numbers that match the part number of the current item
				const serialNumberObjects = serialNumbers.filter(
					(serialNumber) =>
						serialNumber.partNumber.includes(item.uuid)
				);

				// if there are no serial numbers, throw an error
				if (serialNumberObjects.length === 0) {
					setError(`partNumber: ${item.uuid}`, {
						type: "manual",
						message: `Required field.`,
					});
					return;
				} else {
					// clear any errors if there are for this part number
					clearErrors(`partNumber: ${item.uuid}`);
					// format only the serial numbers into an array of objects
					let serialNumbersObjectToArray =
						serialNumberObjects.flatMap((serialNumberObject) => {
							if (typeof serialNumberObject === "object") {
								return Object.values(
									serialNumberObject.serialNumber as string
								);
							}
							return [];
						});

					//add each serial number to the formattedSerialNumbers array
					serialNumbersObjectToArray.forEach((serialNumber) => {
						formattedSerialNumbers.push({
							unit: {
								serialNumber: serialNumber,
								item: {
									partNumber: item.partNumber,
								},
							},
						});
					});
				}

				// SINGLE INPUT SERIAL NUMBER FORMATTING
			} else if (Number(item.quantity) === 1) {
				// find data related to serial numbers
				const singleSerialNumberEntries = Object.entries(data).filter(
					([key]) => key.startsWith("partNumber:")
				);

				// create an array with the partNumber in first element, and the serial numbers in the second
				let itemSerialNumbers = singleSerialNumberEntries.find(
					(serialNumber) => {
						return serialNumber[0].includes(item.uuid);
					}
				);
				if (itemSerialNumbers && itemSerialNumbers[1] !== "") {
					clearErrors(`partNumber: ${item.partNumber}`);
					formattedSerialNumbers.push({
						unit: {
							serialNumber: itemSerialNumbers[1],
							item: {
								partNumber: item.partNumber,
							},
						},
					});
				} else {
					setError(`partNumber: ${item.uuid}`, {
						type: "manual",
						message: `Required field.`,
					});
					return;
				}
			}

			return {
				salesOrderItem: {
					uuid: item.uuid,
				},
				quantity: item.quantity,
				itemFulfillmentItemUnits: formattedSerialNumbers,
			};

			// NON-SERIALIZED ITEM FORMATTING
		} else {
			return {
				salesOrderItem: {
					uuid: item.uuid,
				},
				quantity: item.quantity,
			};
		}
	});

	let requiresSignature;
	if (data.signatureType === "No Signature Required") {
		requiresSignature = false;
	} else if (data.signatureType === "Signature Required") {
		requiresSignature = true;
	} else if (data.signatureType === "None Specified") {
		requiresSignature = null;
	}

	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = String(currentDate.getMonth() + 1).padStart(2, "0");
	const day = String(currentDate.getDate()).padStart(2, "0");
	const formattedDate = `${year}-${month}-${day}`;

	const newData = {
		salesOrder: {
			c_netsuiteInternalSalesOrderId: internalId as string,
		},
		shipToAddress: {
			phoneNumber: data.phoneNumber,
			state: {
				name: data.state,
			},
			addressee: data.addressee,
			attention: data.attention,
			line1: data.line1,
			line2: data.line2,
			zip: data.zip,
			city: data.city,
			uuid: addressUuid,
		},
		dateItemFulfillment: formattedDate,
		itemFulfillmentItems: updatedItems,
		itemFulfillmentPackages: [
			{
				trackingNumber: {
					containsBattery: data.hasBattery,
					requiresSignature: requiresSignature,
					weight: data.weight,
					length: data.dimensions.length,
					width: data.dimensions.width,
					height: data.dimensions.height,
					description: data.name,
					declaredCost: data.declaredCost,
					shippingMethod: {
						uuid: data.shipmentMethod.uuid,
					},
					shippingCarrier: {
						uuid: data.carrier.uuid,
					},
					shippingCarrierAccountNumber: {
						uuid: data.carrierAccountNumber.uuid,
					},
				},
			},
		],
	};
	return newData;
};

export default formatShipmentData;
