// import StatusColorDot from "../components/ui/DataTable/StatusColorDot";
import React, { useContext } from "react";
import getFontAwesomeIcon from "../utils/getFontAwesomeIcon";
import ImageComponent from "../components/layout/ImageComponent";
import placeholderImage from "../assets/placeholder-no-image-available.png";
import StatusColorDot from "../components/ui/Tables/StatusColorDot";
import formatCurrency from "../utils/formatCurrency";
import { CellProps } from "react-table";
import { AnimatePresence, motion } from "framer-motion";
import { BaseToolTip } from "../components/ui";

interface TableColumn {
	Header: string | (() => JSX.Element);
	accessor: string;
	Cell: ({
		value,
	}: CellProps<
		{ [key: string]: string | React.ReactElement },
		string | React.ReactElement
	>) => JSX.Element;

	sticky?: "left" | "right";
}

type ConfigType = {
	key: string;
	path: string;
	hasLeftIcon: boolean;
	hasRightIcon: boolean;
	hasLeftImage: boolean;
	hasRightImage: boolean;
	hasStatus: boolean;
	hasCurrency: boolean;
	hasDate: boolean;
	hasLink: boolean;
	linkClasses: string;
	hasTextOverflow: boolean;
	transform: (value: any) => any;
	type: string;
	isFilterable: boolean;
	isSortable: boolean;
	header: string;
};
const slideFadeVariants = {
	hidden: { opacity: 0, x: 50 },
	visible: {
		opacity: 1,
		x: 0,
		transition: { duration: 0.1, stiffness: 300, damping: 100 },
	},
	exit: {
		opacity: 0,
		x: -50,
		transition: { type: "spring", stiffness: 100, damping: 20 },
	},
};

const calculateMaxWidth = (headerText: string, config: any) => {
	if (headerText.length <= 9 && config.isFilterable && config.isSortable) {
		return "max-w-24";
	} else if (
		headerText.length <= 12 &&
		config.isFilterable &&
		config.isSortable
	) {
		return "max-w-28";
	} else if (
		headerText.length <= 17 &&
		config.isFilterable &&
		config.isSortable
	) {
		return "max-w-32";
	} else if (
		headerText.length >= 18 &&
		config.isFilterable &&
		config.isSortable
	) {
		return "max-w-44";
	}
};

function formatTableData(
	columnTitles: string[], // Array of column titles
	accessors: string[], // Array of accessors corresponding to the column titles
	columnConfigs: any[], // Array of column configuration objects
	typeValues?: Record<string, any>, // object of type values
	showFilterSlugToggle?: boolean,
	visibleListId?: string,
	handleIconClick?: (id: string, rowUuid: string) => void,
	actionList?: React.ReactNode,
	tableData?: Record<string, any>,
	deactivatedMenuUuids?: string[]
): TableColumn[] {
	const renderCellContent = (config: ConfigType) => {
		return ({ value }: { value: string }) => {
			if (config?.hasLeftIcon) {
				const icon = (
					<div className="pr-2">
						{getFontAwesomeIcon("check", "solid")}
					</div>
				);
				return renderContent(config, value, icon, "left");
			}

			if (config?.hasRightIcon) {
				const icon = (
					<div className="pl-2">
						{getFontAwesomeIcon("check", "solid")}
					</div>
				);
				return renderContent(config, value, icon, "right");
			}

			if (config?.hasLeftImage) {
				const image = (
					<ImageComponent
						imageUrl={placeholderImage}
						altText={"Image not available"}
						className={"pr-2"}
						width={30}
						height={30}
					/>
				);
				return renderContent(config, value, image, "left");
			}

			if (config?.hasRightImage) {
				const image = (
					<ImageComponent
						imageUrl={placeholderImage}
						altText={"Image not available"}
						className={"pl-2"}
						width={30}
						height={30}
					/>
				);
				return renderContent(config, value, image, "right");
			}

			if (config?.type === "STATUS") {
				const icon = (
					<div className="flex pr-1.5">
						<StatusColorDot
							status={value as string}
							typeValues={typeValues || {}}
						/>
					</div>
				);
				return renderContent(config, value, icon, "left");
			}

			if (config?.type === "BOOLEAN") {
				const icon = (
					<div className="flex pr-1">
						<StatusColorDot
							status={value as string}
							typeValues={typeValues || {}}
							additionalClasses="flex items-center"
							useCustomColor={
								value === "True"
									? "#2CB224"
									: value === "False"
									? "#FF2E54"
									: undefined
							}
						/>
					</div>
				);
				return renderContent(config, value, icon, "left");
			}

			if (config?.type === "CURRENCY") {
				let formattedValue;
				if (value === "—") {
					formattedValue = value;
				} else {
					formattedValue = formatCurrency(value as string, 2);
				}
				const icon = (
					<div className="pr-2 text-xs">
						{getFontAwesomeIcon("dollarSign", "solid")}
					</div>
				);

				let maxWidth = calculateMaxWidth(config.header, config);
				return renderContent(
					config,
					formattedValue,
					icon,
					"left",
					"justify-between",
					maxWidth
				);
			}

			if (config?.type === "NUMBER") {
				const icon = <div className="display-none"></div>;

				let maxWidth = calculateMaxWidth(config.header, config);

				return renderContent(
					config,
					value,
					icon,
					"left",
					"justify-end",
					maxWidth
				);
			}

			if (config?.type === "DATE") {
				return renderContent(config, value);
			}

			if (config?.linkClasses) {
				return renderContent(config, value);
			}

			return (
				<div style={{ display: "flex", alignItems: "center" }}>
					{typeof value === "string" ? <p>{value}</p> : value}
				</div>
			);
		};
	};

	const renderContent = (
		config: ConfigType | undefined,
		value: string | JSX.Element,
		content?: JSX.Element,
		position?: string,
		justifyContent?: string,
		maxWidth?: string
	) => (
		<div
			style={{ display: "flex", alignItems: "center" }}
			className={`flex w-full ${justifyContent} ${maxWidth}`}
		>
			{config?.linkClasses ? (
				<a
					href="#"
					className={`flex ${config?.linkClasses}`}
					onClick={(e) => e.stopPropagation()}
				>
					{position === "left" && content}
					{typeof value === "string" ? <p>{value}</p> : value}
					{position === "right" && content}
				</a>
			) : (
				<>
					{position === "left" && content}
					{typeof value === "string" ? <p>{value}</p> : value}
					{position === "right" && content}
				</>
			)}
		</div>
	);

	const columns = columnTitles.map((title, i) => {
		const key = `col${i + 1}`;
		const config = columnConfigs.find((c) => c.key === key) || {
			key,
			type: columnConfigs[0]?.default
				? columnConfigs[0]?.default[i]?.type
				: "DEFAULT",
			header: title,
			isFilterable: columnConfigs[0]?.default[i].isFilterable,
			isSortable: columnConfigs[0]?.default[i].isSortable,
		};

		return {
			Header: title,
			accessor: accessors[i],
			Cell: ({ value }) =>
				renderCellContent(config)({ value: value.toString() }),
		} as TableColumn;
	});

	columns.push({
		Header: () => {
			const outerDivClass = "";
			const lineClass = `${
				showFilterSlugToggle ? "left-[-0px]" : "left-[0px]"
			} absolute bg-navy-200  h-full w-[2px] bottom-0 z-50`;
			const innerDivClass = `inner-div-base ${
				showFilterSlugToggle ? "inner-div-large" : "inner-div-small"
			}`;

			return <div className={innerDivClass}></div>;
		},

		accessor: "sticky-column",
		sticky: "right",

		Cell: ({ row, data }) => {
			const id = row?.id;
			let rowUuid = "";

			if (id && tableData && tableData[id] && tableData[id].data) {
				rowUuid = tableData[id].data.uuid;
			}

			const firstRow = row?.index === 0;
			const totalRows = data?.length || 0;
			const moreThanFourRows = data.length >= 4;
			const isLastTwoRows = row?.index >= totalRows - 2;
			const toolTipPosition =
				firstRow || deactivatedMenuUuids?.includes(rowUuid)
					? "left"
					: "top";
			const actionListClass = `${
				isLastTwoRows && moreThanFourRows
					? "top-[-30px]"
					: "top-[-10px]"
			} absolute right-40 size-12`;
			const wrapperClass = `${
				showFilterSlugToggle ? "min-w-24 " : "min-w-16 "
			} bg-gray-50 w-full max-w-12  flex justify-center p-2 flex items-center`;
			const motionDivClass = `${
				visibleListId === id
					? "border-primary bg-supply-blue-50"
					: "bg-white"
			} ${
				deactivatedMenuUuids?.includes(rowUuid)
					? "text-navy-200 cursor-not-allowed hover:border-navy-200"
					: "bg-white text-primary hover:border-primary hover:bg-supply-blue-50"
			} mr-3 relative   rounded border-1 border-navy-200 text-base p-1 size-6 flex items-center justify-center `;
			const arrowDivClass =
				"relative bg-white hover:bg-supply-blue-50 hover:border-primary text-primary rounded border-1 border-navy-200 text-base p-1 size-6 flex items-center justify-center ";
			const stampDivClass = `p-2 rounded relative `;

			return (
				<>
					<div
						style={{
							left: showFilterSlugToggle ? "-10px" : "-10px",
						}}
						className={wrapperClass}
					>
						{showFilterSlugToggle && (
							<div
								onClick={(e) => {
									e.stopPropagation();
									{
										deactivatedMenuUuids?.includes(rowUuid)
											? null
											: handleIconClick &&
											  handleIconClick(id, rowUuid);
									}
								}}
								key={id}
								className={motionDivClass}
							>
								<div className={stampDivClass}>
									<BaseToolTip
										message={"Approval"}
										toolTipPlacement={toolTipPosition}
									>
										{getFontAwesomeIcon("stamp", "regular")}
									</BaseToolTip>
								</div>

								<AnimatePresence>
									{visibleListId === id && (
										<motion.div
											key={`actionList-${id}`}
											initial="hidden"
											animate="visible"
											exit="exit"
											variants={slideFadeVariants}
											className={actionListClass}
										>
											{actionList}
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						)}

						<div className={arrowDivClass}>
							<BaseToolTip
								message={"Open"}
								toolTipPlacement={toolTipPosition}
							>
								{getFontAwesomeIcon("arrowUpRight", "regular")}
							</BaseToolTip>
						</div>
					</div>
				</>
			);
		},
	} as TableColumn);

	return columns;
}

// generateAccessors
// The generateAccessors function takes one parameter: columnTitles. It returns an array of strings that correspond to the columnTitles array.

// Parameters
// columnTitles: An array of strings representing the column titles.
// Return value
// An array of strings that correspond to the columnTitles array.
export function generateAccessors(columnTitles: string[]): string[] {
	return columnTitles.map((title, index) => `col${index + 1}`);
}

export default formatTableData;
