//////// GENERAL IMPORTS ////////
import React, {
	JSXElementConstructor,
	ReactElement,
	useEffect,
	useState,
} from "react";
import {
	useTable,
	usePagination,
	TableInstance,
	UsePaginationInstanceProps,
} from "react-table";
import { AnimatePresence, motion } from "framer-motion";
////////  HOOK IMPORTS ////////
import {
	useConstructUrl,
	useDarkMode,
	useFetchData,
	useHandleClear,
	useHandlePopState,
	useHostName,
	usePopState,
	useUrl,
	useDataTableState,
	useItemsContext,
	useDataTableContext,
	useHandleRowClick,
	useUrlSync,
	useManageModalUrl,
	usePrivilegeCheck,
} from "../../../../hooks";
////////  TABLE IMPORTS ////////
import formatTableData, {
	generateAccessors,
} from "../../../../utils/formatTableData";
import convertDataUtil, { generateDynamicConfig } from "../convertData";
////////  TYPE IMPORTS ////////
import { CustomTableState, PrimaryTableProps } from "../types";
////////  COMPONENT IMPORTS ////////
import TableContent from "./PrimaryTableContent";
import { LoadingMessage, Toaster } from "../../../misc";
import {
	findValueInString,
	flattenArray,
	replaceStatusWithNames,
	updateUrlWithNewPage,
} from "../../../../utils";
import TablePagination from "../../TablePagination";
import { useSticky } from "react-table-sticky";
import { tableVariants } from "../../../../motionVariants";
import { ModalType } from "../../../../pages/Orders/view/modals/ApprovalView/types";
import ActionList from "../ActionList";
import { renderModalContent } from "./helperFunctions/renderModalContent";
import { updateRowData } from "./helperFunctions/updateRowData";
import { renderQuickActionModal } from "./helperFunctions/renderQuickActionModal";
import { quickActionListData } from "./helperFunctions/quickActionListData";
import { useLocation } from "react-router-dom";
import { set } from "react-hook-form";

export type StatusReplacement = {
	slug: string;
	name: string;
	colorHex: string;
};

const PrimaryDataTable: React.FC<PrimaryTableProps> = ({
	slug,
	setHasModalTable,
	additionalData,
	callFromFilter,
	setCallFromFilter,
	onFiltersChange,
}) => {
	const { salesOrderStageId } = additionalData;

	const { darkMode } = useDarkMode();
	const {
		activeIndex,
		setActiveIndex,
		currentPage,
		setCurrentPage,
		isLoading,
		setIsLoading,
		setNoDataFound,
		isError,
		setIsError,
		data,
		setData,
		convertedData,
		setConvertedData,
		sortColumn,
		setSortColumn,
		resetSearch,
		setResetSearch,
		editingHeader,
		setEditingHeader,
		setHeaderValue,
		searchText,
		setSearchText,
		submittedSearchText,
		setSubmittedSearchText,
		detailsAreUndefined,
		setDetailsAreUndefined,
		showDetailsBox,
		setShowDetailsBox,
		columnTitles,
		setColumnTitles,
		totalPages,
		setTotalPages,
		columnMappings,
		setColumnMappings,
		searchColumn,
		setSearchColumn,
		clearSearch,
		prevPageFromURL,
		setPrevPageFromURL,
		recordsPerPageAfterChange,
		setRecordsPerPageAfterChange,
		paginationInput,
		setItemUuid,
		dataTableValueFields,
		setDataTableValueFields,
		setTotalRecords,
		totalRecords,
	} = useDataTableState();

	//TODO: all this data for the list can come from the back end
	const [activeModal, setActiveModal] = useState<ModalType>(null);
	const location = useLocation();
	const {
		activeUuid,
		setActiveUuid,
		setActiveRowUuid,
		activeRowUuid,
		deactivatedQuickMenuIds,
		setDeactivatedQuickMenuIds,
	} = useItemsContext();
	const { previousUrl, setPreviousUrl } = useUrl();

	const [visibleListId, setVisibleListId] = useState<string | null>(null);
	const [rowData, setRowData] = useState<any[]>([]);
	const [isRowDataInitialized, setIsRowDataInitialized] = useState(false);
	const { hostName } = useHostName();
	const rowsPerPage = [10, 15, 20, 25, 50, 100];
	const { isAdmin } = usePrivilegeCheck();
	const keysArray =
		rowData.length > 0 ? Object.keys(rowData[0]?.data || {}) : [];
	const handleUpdateRowData = (uuid: string, key: string, newValue: any) => {
		updateRowData({
			uuid,
			key,
			newValue,
			setRowData,
			keysArray,
		});
	};

	const listData = quickActionListData(
		setActiveModal,
		activeRowUuid || "",
		isAdmin
	);

	const { pageMeta, pageTitle } = useDataTableContext();
	const [typeValues, setTypeValues] = useState<Record<string, any>>();

	const {
		isModalOpen,
		setIsModalOpen,
		toggleModal,
		setSearchColumn: setSearchColumnFromContext,
		searchCriteria,
		hasSort,
		setHasSort,
	} = useItemsContext();
	//Set the rowData from the data that comes in from the API call so mutable data can be used for the table live updates
	useEffect(() => {
		if (data && data.length > 0 && !isRowDataInitialized) {
			setRowData(data);
			setIsRowDataInitialized(true);
		}
	}, [data, isRowDataInitialized]);

	useManageModalUrl(
		pageTitle,
		activeUuid,
		setActiveUuid,
		isModalOpen,
		setIsModalOpen,
		setCallFromFilter,
		callFromFilter
	);

	const { handleClear } = useHandleClear(
		setDetailsAreUndefined,
		setNoDataFound,
		setSearchText,
		setSubmittedSearchText,
		setSortColumn,
		setResetSearch,
		setCurrentPage,
		resetSearch
	);

	const convertColumnTitles = (pageMeta: any, dataTableValueFields: any) => {
		return dataTableValueFields.map((field: any) => {
			const table = field.settings.table;
			const fieldName = field.settings.field;
			// Retrieve the label-singular from pageMeta
			const labelSingular =
				pageMeta.settings.fields[table]?.[fieldName]?.[
					"label-singular"
				] || "";
			return {
				labelSingular,
			};
		});
	};

	const convertedColumnTitles = flattenArray(
		convertColumnTitles(pageMeta, dataTableValueFields)
	);
	const quickActionModal = renderQuickActionModal({
		activeModal,
		setActiveModal,
		activeRowUuid: activeRowUuid || "",
		activeUuid: activeUuid || "",
		handleUpdateRowData,
		setDeactivatedQuickMenuIds,
	});

	const actionList = (
		<>
			<ActionList
				listData={listData}
				containerId={"portal-root"}
				activeRowUuid={activeRowUuid || ""}
				setQuickMenuVisible={setVisibleListId}
			/>
		</>
	);

	const handleIconClick = (id: string, rowUuid: string) => {
		setActiveRowUuid(rowUuid);
		setVisibleListId((prevId) => (prevId === id ? null : id));
	};

	const accessors = generateAccessors(convertedColumnTitles);
	const columns = React.useMemo(() => {
		const dynamicConfig = generateDynamicConfig(
			dataTableValueFields as any
		);

		return formatTableData(
			convertedColumnTitles,
			accessors,
			[dynamicConfig],
			typeValues || [],
			callFromFilter,
			visibleListId || "",
			handleIconClick,
			actionList,
			rowData,
			deactivatedQuickMenuIds
		);
	}, [
		rowData,
		columnTitles,
		dataTableValueFields,
		callFromFilter,
		visibleListId,
	]);

	useEffect(() => {
		const dynamicConfig = generateDynamicConfig(
			dataTableValueFields as any
		);

		// Ensure `replaceStatusWithNames` is called with correct data
		const updatedProcessedData = replaceStatusWithNames(
			rowData,
			Array.isArray(typeValues) ? typeValues : []
		);

		// Set converted data with the processed data
		setConvertedData(
			convertDataUtil(updatedProcessedData as any, dynamicConfig)
		);
	}, [rowData, dataTableValueFields, typeValues]);

	useEffect(() => {
		const dynamicConfig = generateDynamicConfig(
			dataTableValueFields as any
		);

		// Process and convert data within this effect
		const updatedProcessedData = replaceStatusWithNames(
			data,
			Array.isArray(typeValues) ? typeValues : []
		);

		// Set converted data with the processed data
		setConvertedData(
			convertDataUtil(updatedProcessedData as any, dynamicConfig)
		);
		if (data && data.length > 0) {
			setRowData(data);
		}
	}, [data, dataTableValueFields, typeValues]);

	const instance = useTable<{ [key: string]: string | React.ReactElement }>(
		{
			columns,
			data: convertedData,
			initialState: {
				pageIndex: 0,
				pageSize: recordsPerPageAfterChange,
			} as any,
		},
		useSticky,
		usePagination
	) as TableInstance<{ [key: string]: string | React.ReactElement }> &
		UsePaginationInstanceProps<{
			[key: string]: string | React.ReactElement;
		}>;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		state,
		setPageSize,
		prepareRow,
	} = instance;

	const { pageSize } = state as CustomTableState<{
		[key: string]:
			| string
			| ReactElement<any, string | JSXElementConstructor<any>>;
	}>;

	// TODO: once we have the api call for the Approvals use the effectiveSlug
	const fetchData = useFetchData({
		slug,
		setIsLoading,
		setColumnMappings,
		setData,
		setColumnTitles,
		setTotalPages,
		setIsError,
		setNoDataFound,
		setDataTableValueFields,
		setTypeValues,
		additionalData,
		setTotalRecords,
		pageTitle,
	});

	const handlePopState = useHandlePopState(
		prevPageFromURL,
		setPrevPageFromURL,
		fetchData
	);

	const constructedUrl = useConstructUrl({
		slug,
		searchCriteria,
		pageSize,
		sortColumn,
		columnMappings,
		page: currentPage,
		pageMeta,
		additionalData,
	});

	// Using the usePopState custom hook to handle popstate events
	usePopState(handlePopState);
	//Initializes page title and fetches initial data on component mount, Sets up popstate event listeners for navigation, and cleans up on unmount
	useEffect(() => {
		if (hostName === "") return;
		setHasModalTable && setHasModalTable(true);
		const filter = localStorage.getItem("approvalFilter");
		if (filter && !salesOrderStageId && hostName === "COMPASS") {
			return;
		} else {
			fetchData();
		}

		if (submittedSearchText !== undefined) {
			localStorage.setItem("submittedSearchText", submittedSearchText);
		}
		setVisibleListId(null);
		setSearchColumnFromContext(searchColumn?.Header || "");
		// setHasActiveSort(findValueInString(location.search, "sort"));
	}, [
		pageSize,
		slug,
		currentPage,
		sortColumn,
		submittedSearchText,
		hostName,
		clearSearch,
		resetSearch,
		paginationInput,
		callFromFilter,
		salesOrderStageId,
		// additionalData,
		searchCriteria,
	]);
	useEffect(() => {
		// Ensure `where` clause or any additional data is in `constructedUrl`
		if (constructedUrl && constructedUrl.includes("where")) {
			fetchData();
		}
	}, [constructedUrl]);

	// Updates sortColumn without adding invalid keys (e.g., col)
	const updateSortColumn = (key: string, direction: string) => {
		setSortColumn((prev: any) => {
			// Clone the existing sortColumn object
			const updatedSortColumn = { ...prev };
			// Update or add the key with its direction
			updatedSortColumn[key] = `${direction}${key}`;
			// Return the updated sortColumn object
			return updatedSortColumn;
		});
	};
	// Handles sort changes from headers
	const handleHeaderSortClick = (columnKey: string) => {
		const currentDirection =
			sortColumn[columnKey]?.[0]?.[0] === "+" ? "-" : "+";
		updateSortColumn(columnKey, currentDirection);
	};
	//Updates URL parameters and synchronizes state with URL changes, including sorting, searching, and pagination
	useUrlSync({
		previousUrl,
		setPreviousUrl,
		constructedUrl,
		currentPage,
		setCurrentPage,
		setSearchText,
		submittedSearchText,
		sortColumn,
		pageSize,
		updateUrlWithNewPage,
		slug,
		setRecordsPerPageAfterChange,
	});

	useEffect(() => {
		setPageSize(Number(recordsPerPageAfterChange));
	}, [recordsPerPageAfterChange, setPageSize]);

	const setCurrentPageAndUpdateUrl = (newPage: number) => {
		setCurrentPage(newPage);
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("page", String(newPage));
	};

	const handleRowClick = useHandleRowClick(
		data,
		setActiveIndex,
		setShowDetailsBox,
		setItemUuid
	);
	useEffect(() => {
		const newUrl = `${window.location.pathname}${constructedUrl}`;
		window.history.replaceState(null, "", newUrl);
	}, [constructedUrl]);

	useEffect(() => {
		if (onFiltersChange) {
			const hasSearchCriteria =
				Array.isArray(searchCriteria) && searchCriteria.length > 0;

			const urlSearchParams = new URLSearchParams(location.search);
			const hasSort = Array.from(urlSearchParams.keys()).some((key) =>
				key.includes("_sort")
			);

			const isFilterActive = hasSearchCriteria || hasSort;
			onFiltersChange(isFilterActive);
		}
	}, [searchCriteria, location.search]);

	if (isLoading) {
		return (
			<div className=" flex items-center justify-center min-h-[750px] max-h-[750px]">
				<LoadingMessage
					message=""
					containerClasses="items-center justify-center mt-32 "
				/>
			</div>
		);
	}

	if (isError) {
		return (
			<Toaster
				message="Something went wrong"
				color="red-500"
				additionalClasses="flex justify-center"
			/>
		);
	}

	if (detailsAreUndefined) {
		return (
			<Toaster
				clearMessage={handleClear}
				message="Search returned no results"
				color="red-500"
			/>
		);
	}
	// let modal;
	let tableClasses;
	//activeRow is used to determine if the clicked row's status is "pendingInitialApproval"
	const activeRowStatus = rowData.find((row) => row.data.uuid === activeUuid);
	//decides what modal to render
	const modal = renderModalContent({
		pageTitle,
		isModalOpen,
		toggleModal,
		data,
		hostName,
		activeRowStatus,
		updateRowData: handleUpdateRowData,
	});

	return (
		<AnimatePresence>
			<motion.div {...tableVariants} className="h-[calc(100%-82px)]">
				<div
					className={`parent-container  h-full ${
						darkMode ? "text-darkText" : "text-black"
					}`}
				>
					<div className={`${tableClasses} h-full`}>
						<div className={`min-w-full h-full`}>
							<div className="ut-wrapper max-w-full min-w-full h-full border-1 border-navy-250 rounded-lg ">
								<div
									className={`h-[calc(100%-72px)] ${
										darkMode
											? "grid max-w-full overflow-x-scroll"
											: "ut-inner px-0"
									}`}
								>
									<div className="relative h-full">
										<TableContent
											headerGroups={headerGroups}
											data={data}
											page={page}
											getTableProps={getTableProps}
											getTableBodyProps={
												getTableBodyProps
											}
											prepareRow={prepareRow}
											activeIndex={activeIndex}
											handleRowClick={handleRowClick}
											handleClear={handleClear}
											setSearchColumn={setSearchColumn}
											showDetailsBox={showDetailsBox}
											setResetSearch={setResetSearch}
											editingHeader={editingHeader}
											searchText={searchText}
											setSearchText={setSearchText}
											setSubmittedSearchText={
												setSubmittedSearchText
											}
											setSortColumn={updateSortColumn}
											setEditingHeader={setEditingHeader}
											setHeaderValue={setHeaderValue}
											columnMappings={columnMappings}
											slug={slug}
											filterApplied={
												callFromFilter || false
											}
											onHeaderSortClick={
												handleHeaderSortClick
											}
										/>
									</div>
								</div>
								{data.length !== 0 && (
									<TablePagination
										recordCount={data.length}
										pageSize={pageSize}
										rowsPerPage={rowsPerPage}
										setPageSize={setPageSize}
										showSlideMenu={showDetailsBox}
										totalPages={totalPages}
										setCurrentPageInParent={setCurrentPage}
										currentPage={currentPage}
										setCurrentPageAndUpdateUrl={
											setCurrentPageAndUpdateUrl
										}
										totalRecordCount={totalRecords}
									/>
								)}
							</div>
						</div>
					</div>
					{modal}
					{quickActionModal}
				</div>
			</motion.div>
		</AnimatePresence>
	);
};

export default PrimaryDataTable;
