import React, { useEffect, useRef, useState } from "react";
import DropDown from "./DropDown";
import useTablePagination from "../../hooks/dataTableHooks/useTablePagination";
import { useNavigate } from "react-router-dom";
import { getFontAwesomeIcon } from "../../utils";
import onlyAllowNumberValue from "../../utils/onlyAllowNumber";

interface PageNavigationButtonProps {
	icon: string;
	onClick: () => void;
	isDisabled?: boolean;
}

interface TablePaginationProps {
	pageSize: number;
	setPageSize: (newSize: number) => void;
	setCurrentPageInParent: React.Dispatch<React.SetStateAction<number>>;
	showSlideMenu: boolean;
	totalPages?: number;
	currentPage: number;
	setCurrentPageAndUpdateUrl: (newPage: number) => void;
	rowsPerPage: number[];
	renderDropDown?: boolean;
	setPaginationInput?: React.Dispatch<React.SetStateAction<number>>;
	recordCount?: number;
	totalRecordCount: number;
}
function getPageRange(
	currentPage: number,
	pageSize: number,
	totalPages: number,
	recordCount: number,
	totalRecordCount: number
): string {
	const start = (currentPage - 1) * pageSize + 1;
	let end: number;

	if (currentPage === totalPages) {
		end = totalRecordCount;
	} else if (totalPages && totalPages <= 1) {
		end = recordCount;
	} else {
		end = Math.min(currentPage * pageSize, totalPages * pageSize);
	}

	return `${start} – ${end}`;
}

const TablePagination: React.FC<TablePaginationProps> = ({
	pageSize,
	setPageSize,
	showSlideMenu,
	totalPages,
	setCurrentPageInParent,
	rowsPerPage,
	currentPage,
	setCurrentPageAndUpdateUrl,
	renderDropDown = true,
	recordCount,
	totalRecordCount,
}) => {
	const navigate = useNavigate();
	const paginationContainerRef = useRef<HTMLDivElement>(null);
	const [showPaginationOptions, setShowPaginationOptions] = useState(false);
	const [inputNumberValue, setInputValueNumber] = useState("");
	const onPageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const newSize = Number(e.target.value);
		const newPage = Math.min(
			currentPage,
			Math.ceil((totalPages! * pageSize) / newSize)
		);
		setCurrentPageInParent(newPage);
		setPageSize(newSize);
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("page", String(newPage));
		navigate(`?${searchParams.toString()}`, { replace: true });
	};

	const safeTotalPages = totalPages || 1;

	const { handleFirstPage, handlePrevPage, handleNextPage, handleLastPage } =
		useTablePagination({
			initialPage: currentPage,
			totalPages: safeTotalPages,
			onPageChange: setCurrentPageInParent,
		});

	useEffect(() => {
		if (showPaginationOptions && paginationContainerRef.current) {
			const activePageElement =
				paginationContainerRef.current.querySelector(".active-page");

			if (activePageElement) {
				const container = paginationContainerRef.current;
				const activePageElementAsHTMLElement =
					activePageElement as HTMLElement;
				container.scrollTop =
					activePageElementAsHTMLElement.offsetTop -
					container.offsetTop -
					container.clientHeight / 2 +
					activePageElementAsHTMLElement.clientHeight / 2;
			}
		}
	}, [showPaginationOptions]);

	const PageNavigationButton: React.FC<PageNavigationButtonProps> = ({
		icon,
		onClick,
		isDisabled,
	}) => {
		return (
			<button
				disabled={isDisabled}
				className={`${
					isDisabled
						? "border-200"
						: "border-navy-200 hover:bg-primaryVeryLight hover:border-primary  cursor-pointer"
				} bg-white  text flex justify-center w-full border-1  rounded p-1 min-w-7 `}
				onClick={onClick}
			>
				<div
					className={`${
						isDisabled ? "text-navy-200" : "text-primary"
					} text-xs flex p-1 items-center justify-center w-full`}
				>
					{getFontAwesomeIcon(icon, "solid")}
				</div>
			</button>
		);
	};

	const handleInputClick = () => {
		if (totalPages !== undefined && Number(inputNumberValue) > totalPages) {
			setCurrentPageAndUpdateUrl(Number(totalPages));
			return;
		}
		if (totalPages !== undefined && Number(inputNumberValue) === 0) {
			setCurrentPageAndUpdateUrl(1);
			return;
		}

		setCurrentPageAndUpdateUrl(Number(inputNumberValue));
	};

	return (
		<div className="flex w-full bg-white relative z-40 max-h-[70px]">
			<div className="w-full flex flex-col sm:flex-row items-center gap-8 justify-between pt-4 border-t-1 border-navy-200 p-5">
				{renderDropDown && (
					<div className="ut-per-page inline-flex items-baseline order-2 sm:order-1 sm:w-auto w-full ">
						<DropDown
							defaultValue={pageSize}
							onChange={onPageSizeChange}
							options={rowsPerPage}
							additionalClasses=""
							itemsTableDropdown
							position="up"
						/>
						{!showSlideMenu && (
							<span className="text-navy-270 pl-2">per page</span>
						)}
					</div>
				)}

				<div
					className={`flex  text-sm items-center gap-2 order-1 sm:order-2 sm:w-auto w-full justify-between`}
				>
					<div className="flex">
						<span>
							{getPageRange(
								currentPage,
								pageSize,
								totalPages || 1,
								recordCount || 0,
								totalRecordCount
							)}
						</span>

						<div className="text-navy-300 pr-4">
							<span className="px-1">of</span>
							<span>{totalRecordCount}</span>
						</div>
					</div>
					<div className="gap-2 flex items-center justify-between">
						<PageNavigationButton
							icon="chevronsLeft"
							isDisabled={currentPage === 1}
							onClick={() => handleFirstPage()}
						/>
						<PageNavigationButton
							icon="chevronLeft"
							isDisabled={currentPage === 1}
							onClick={handlePrevPage}
						/>
					</div>
					<div className="max-w-20 flex relative overflow-hidden group hover:cursor-pointer">
						<input
							className="h-[31px] border-1 w-full border-navy-200 rounded size-7 text-center focus:outline-none focus:border-primary hover:border-primary hover:bg-primaryVeryLight hover:text-primary group-hover:border-primary focus:pr-8 focus:pl-1 transition-all duration-100"
							type="text"
							value={inputNumberValue}
							placeholder={currentPage.toString()}
							onChange={(event) =>
								onlyAllowNumberValue(event, setInputValueNumber)
							}
						/>

						<div
							onClick={handleInputClick}
							className="border-1 bg-white text-primary hover:bg-primaryVeryLight border-primary top-0 flex items-center justify-center right-0 absolute h-full w-1/3 rounded-tr rounded-br opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity duration-0"
						>
							{getFontAwesomeIcon("check", "regular")}
						</div>
					</div>
					<div className=" flex justify-between text-navy-300">
						<span>of {totalPages}</span>
					</div>

					<div className="flex items-center justify-between gap-2">
						<PageNavigationButton
							icon="chevronRight"
							isDisabled={currentPage === totalPages}
							onClick={() => handleNextPage()}
						/>
						<PageNavigationButton
							icon="chevronsRight"
							isDisabled={currentPage === totalPages}
							onClick={() => handleLastPage()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TablePagination;
