import React, { useState } from "react";
import BaseText from "../../../../../components/ui/BaseText";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import { getFontAwesomeIcon } from "../../../../../utils";
import BaseBadge from "../../../../../components/ui/BaseBadge";
import { motion, AnimatePresence } from "framer-motion";
import BundleItem from "./BundleItem";
import BundleFee from "./BundleFee";
import { BundleItemProps, ApprovalContentItem, BundleProps } from "./types";
import { usePrivilegeCheck } from "../../../../../hooks";


// FIGMA DOCUMENTATION (lease pricing fields + displaying bundles+bundle items): https://www.figma.com/design/wtVOcbRCEL5r3Kj0UgrB4C/Supply?node-id=122-12


const Bundle: React.FC<BundleProps> = ({ bundle }) => {
	const { isAdmin } = usePrivilegeCheck();
	const [expandedItems, setExpandedItems] = useState<string[]>([]);
	const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);

	const formatBundleItems = (itemType: string) => {
		if (itemType === "Secondary" && bundle.bundleItems) {
			const secondaryItems = bundle.bundleItems.filter(
				(bundle: ApprovalContentItem) =>
					bundle.BundleItemGroups.name === "Secondary"
			);

			return secondaryItems;
		} else if (itemType === "Tertiary" && bundle.bundleItems) {
			const tertiaryItems = bundle.bundleItems.filter(
				(bundle: ApprovalContentItem) =>
					bundle.BundleItemGroups.name === "Tertiary" &&
					bundle.PriceTypes.name !== "Lease"
			);

			return tertiaryItems;
		} else if (itemType === "Lease" && bundle.bundleItems) {
			const leaseItems = bundle.bundleItems.filter(
				(bundle: ApprovalContentItem) =>
					bundle.PriceTypes.name === "Lease"
			);

			return leaseItems;
		}
	};

	const toggleExpand = (id: string) => {
		setExpandedItems((prev) =>
			prev.includes(id)
				? prev.filter((item) => item !== id)
				: [...prev, id]
		);
	};


	const getBadgeClasses = (badgeText?: string) => {
		const baseClasses =
			"h-6 flex justify-center items-center text-xs font-inter-medium whitespace-nowrap overflow-hidden text-ellipsis rounded";

		switch (badgeText) {
			case "Required":
				return `${baseClasses} bg-cyan-light-3 text-cyan-dark`;
			case "Kit":
				return `${baseClasses} bg-secondary-200 text-primary-800`;
			case "Computer Support":
				return `${baseClasses} bg-yellow-light-4 text-yellow-dark-2`;
			case "Primary":
				return `${baseClasses} bg-green-light-6 text-green-dark`;
			case "Add-on":
				return `${baseClasses} bg-addon-light text-primary-text`;
			default:
				return `${baseClasses} bg-gray-100 text-gray-600`;
		}
	};

	const renderBadges = (badgeText?: string) => {
		return (
			<BaseBadge
				text={
					<BaseText
						color=""
						fontFamily=""
						tag="p"
						text={badgeText}
						size=""
						additionalClasses="text-xs font-inter-medium px-2 py-1 whitespace-nowrap overflow-hidden text-ellipsis"
					/>
				}
				badgeContainerClasses={getBadgeClasses(badgeText)}
			/>
		);
	};

	return (
		<div
			key={bundle.uuid}
			className="relative w-full py-3 hover:cursor-pointer"
			onClick={() => toggleExpand(bundle.uuid)}
			onMouseEnter={() => setHoveredItemId(bundle.uuid)}
			onMouseLeave={() => setHoveredItemId(null)}
		>
			<BaseContainer
				columnContainerClasses={`p-2 mb-1 rounded-lg hover:border-secondary-800 transition-all duration-200 w-full border-transparent`}
				columnShape="semi-rounded"
				isDisabled={false}
			>
				<div className="flex justify-between items-center w-full">
					<div className="flex">
						<div
							className={`mr-6 bg-secondary-200 p-3 rounded-lg flex items-center justify-center h-full w-16 `}
						>
							<div className="text-3xl text-gray-700">
								{getFontAwesomeIcon("BoxesStacked", "light")}
							</div>
						</div>
						<div className="flex flex-col text-left">
							<div className="flex flex-row gap-2">
								{renderBadges("Kit")}
								{bundle.PriceTypes.name === "Lease" && (
									<div className="flex flex-row gap-2">
										{renderBadges("Computer Support")}
									</div>
								)}
							</div>
							<BaseText
								text={bundle.Bundles.name}
								size="text-base"
								color="text-gray-700"
								fontFamily="font-inter-semibold font-semibold"
								tag="h3"
								additionalClasses="py-1"
							/>
							<BaseText
								text={bundle.Items.partNumber}
								size="text-sm"
								color="text-primary-text"
								fontFamily="font-inter-regular"
								tag="p"
								additionalClasses="mb-1"
							/>
							<div className="flex flex-row items-center gap-3">
								<BaseText
									text={`$${bundle._extPriceBundlePurchase}`}
									size="text-sm"
									color="text-gray-700"
									fontFamily="font-inter-regular"
									tag="p"
								/>
								{bundle._extPriceBundleLease !== "0.00" && (
									<>
										{"+"}
										<BaseText
											text={`$${bundle._extPriceBundleLease}/mo `}
											size="text-sm"
											color="text-gray-700"
											fontFamily="font-inter-regular"
											tag="p"
										/>
									</>
								)}

								<BaseText
									text={`Qty: ${parseInt(
										bundle.quantity,
										10
									)}`}
									size="text-sm"
									color="text-gray-700"
									fontFamily="font-inter-medium"
									tag="p"
								/>
							</div>
						</div>
					</div>
					<div className="flex items-center gap-14">
						{hoveredItemId === bundle.uuid && (
							<BaseText
								text={
									expandedItems.includes(bundle.uuid)
										? "Collapse"
										: "Expand"
								}
								size="text-sm"
								color="text-gray-7"
								fontFamily="font-inter-medium"
								tag="p"
							/>
						)}
						<motion.div
							className={`text-xl transition-colors duration-200 ${
								hoveredItemId === bundle.uuid
									? "text-primary-800"
									: "text-gray-7"
							}`}
							initial={{ rotate: 0 }}
							animate={{
								rotate: expandedItems.includes(bundle.uuid)
									? 180
									: 0,
							}}
							transition={{
								duration: 0.3,
								ease: "easeInOut",
							}}
						>
							{expandedItems.includes(bundle.uuid)
								? getFontAwesomeIcon("CircleChevronUp", "solid")
								: getFontAwesomeIcon(
										"CircleChevronDown",
										"solid"
								  )}
						</motion.div>
					</div>
				</div>
				<AnimatePresence>
					{expandedItems.includes(bundle.uuid) && (
						<motion.div
							initial={{ opacity: 0, height: 0 }}
							animate={{ opacity: 1, height: "auto" }}
							exit={{ opacity: 0, height: 0 }}
							transition={{ duration: 0.3, ease: "easeInOut" }}
							className="mt-4 text-sm text-gray-700"
						>
							<div className="text-left ml-20">
								<BaseText
									color="text-gray-700"
									fontFamily=""
									tag="p"
									text={"Kit Contents"}
									size=""
									additionalClasses="text-md font-inter-semibold font-semibold px-2 py-1"
								/>
							</div>
							<BundleItem
								key={bundle.Bundles.uuid}
								item={bundle}
								renderBadges={renderBadges}
							/>
							{(formatBundleItems("Secondary") ?? []).map(
								(item: BundleItemProps) => (
									<BundleItem
										key={item.uuid}
										item={item}
										renderBadges={renderBadges}
									/>
								)
							)}
							{(formatBundleItems("Tertiary") ?? []).map(
								(item: BundleItemProps) => (
									<BundleItem
										key={item.uuid}
										item={item}
										renderBadges={renderBadges}
									/>
								)
							)}
							{(formatBundleItems("Lease") ?? []).length > 0 &&
								isAdmin && (
									<div className="flex flex-col ml-20 pl-2">
										<div className="flex justify-start">
											<BaseText
												color="text-gray-700"
												fontFamily=""
												tag="p"
												text={"Additional Fees"}
												size=""
												additionalClasses="text-md font-inter-medium font-semibold mb-2"
											/>
										</div>

										{(formatBundleItems("Lease") ?? []).map(
											(item: BundleItemProps) => (
												<BundleFee
													bundleFee={item}
													key={item.uuid}
												/>
											)
										)}
									</div>
								)}
						</motion.div>
					)}
				</AnimatePresence>
			</BaseContainer>
		</div>
	);
};

export default Bundle;
