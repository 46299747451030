import React, { ReactNode } from "react";
import OrderDetailRow from "./OrderDetailRow";
import BaseContactInfo from "../../../../../components/ui/BaseContactInfo";

type GenericData = {
	[dataLabel: string]: object;
};

interface Field {
	id: string;
	labelIcon?: string;
	label: string;
	valueKey: string;
	inputType?: string;
	labelTextClasses?: string;
	valueTextClasses?: string;
	hasValueLeftIcon?: string;
	hasValueRightIcon?: string;
	hasValueImage?: string;
	detailGridClasses?: string;
	inputGridClasses?: string;
	additionalInputContainerClasses?: string;
	isBoolean?: boolean;
	truthyValue?: string;
	falsyValue?: string;
	isConcatenated?: string;
	isEditOnly?: boolean;
	inputHasLeftIcon?: string;
	inputIconClasses?: string;
	disabledClasses?: string;
	detailIconClasses?: string;
}

interface OrderContentSectionTypes<T extends GenericData> {
	data?: T;
	dataLabel?: string;
	fields?: Field[];
	children?: ReactNode;
	itemContainerClasses?: string;
	iconStyle?: "solid" | "regular" | "light";
	mobileBreakpoint?: string;
	sectionContainerClasses?: string;
	valueType?: "detail" | "input";
	inputToggle?: boolean;
	toggleOnClick?: (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => void;
	sectionTitle?: ReactNode;
	detailsContainerClasses?: string;
	isDisabled?: boolean;
	onInputChange?: (inputKey: string, value: any) => void;
	isContact?: boolean;
	hostName?: string;
}

// TODO: look into TS error w/ any
const OrderContentSection = <T extends { [key: string]: any }>({
	sectionTitle,
	data,
	dataLabel,
	fields,
	itemContainerClasses,
	iconStyle = "regular",
	mobileBreakpoint,
	sectionContainerClasses,
	detailsContainerClasses,
	isDisabled,
	children,
	hostName,
}: OrderContentSectionTypes<T>) => {
	let sectionContent;

	if (!children) {
		sectionContent = fields?.map((field) => {
			// function that allows us to access nested object props.
			// regardless of how nested they are
			let value: any;
			value = field.valueKey
				.split(".")
				.reduce((accum, currentValue) => accum?.[currentValue], data);

			if (value === undefined || value === null || value === "") {
				value = "—";
			} else if (
				field.valueKey === "_totalLease" &&
				field.label === "Computer Support"
			) {
				value = `${value}/mo`;
			} else if (
				field.valueKey === "_totalLease" &&
				field.label === "Total"
			) {
				value = `${value}/mo`;
			}

			return (
				<div key={field.id}>
					<OrderDetailRow
						hostName={hostName}
						dataItem={value}
						allData={data}
						field={field}
						allFields={fields}
						containerWidth={itemContainerClasses}
						mobileBreakpoint={mobileBreakpoint}
						iconStyle={iconStyle}
						isDisabled={isDisabled}
					/>
				</div>
			);
		});
	} else {
		sectionContent = children;
	}

	return (
		<section className={`${sectionContainerClasses}`}>
			{sectionTitle}
			<div className={`${detailsContainerClasses}`}>{sectionContent}</div>
		</section>
	);
};

export default OrderContentSection;
