import React, { useState, useContext } from "react";
import { faRightFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserContext from "../../hooks/useUserContext";
import { useNavigate } from "react-router-dom";

interface LogoutProps {
	show: boolean;
}

const Logout: React.FC<LogoutProps> = ({ show }) => {
	const { user, setUser } = useContext(UserContext);
	const history = useNavigate();
	const [showLogout, setShowLogout] = useState(show);
	const handleLogout = () => {
		setShowLogout(false);
		localStorage.setItem("theme", "togaSupply");
		localStorage.removeItem("accessToken");
		localStorage.removeItem("submittedSearchText");
		localStorage.removeItem("activeHeader");
		localStorage.removeItem("activeSearchColumn");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("darkMode");
		setUser({ ...user, isUserLoggedIn: false });
		history("/login");
		window.location.reload();
	};
	return (
		<>
			{showLogout && (
				<div
					data-testid="logout-button"
					onClick={() => handleLogout()}
					className=" flex items-center justify-center cursor-pointer h-full position: absolute right-8 align-items: center; top-20"
				>
					<span className="bg-white text-red-500 py-4 px-6 text-sm border-2 border-primary rounded-lg hover:bg-blue-100">
						<FontAwesomeIcon icon={faRightFromBracket} /> Logout
					</span>
				</div>
			)}
		</>
	);
};

export default Logout;
